import { gql } from '@apollo/client';
import { SPACE_FRAGMENT } from '../Space/queries';

export const SECTION_FRAGMENT = gql`
  fragment SectionFields on CommunitySection {
    id
    name
    communitySpaces @connection(key: "communitySpaces") {
      totalCount
      edges {
        node {
          ...SpaceFields
          posts {
            edges {
              node {
                id
              }
            }
            totalCount
          }
        }
      }
    }
  }
  ${SPACE_FRAGMENT}
`;

export const SECTION_QUERY = gql`
  query SectionList(
    $communityId: ID!
    $isCoursesToCommunitiesEnabled: Boolean!
  ) {
    community(id: $communityId) {
      id
      communitySections {
        edges {
          node {
            ...SectionFields
          }
        }
      }
    }
  }
  ${SECTION_FRAGMENT}
`;
