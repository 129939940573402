import { SITE_ADMIN, USER_ROLES } from 'utils/constants';

export const isModerator = (userRoles) =>
  userRoles && userRoles.some((role) => role.split(', ').includes(SITE_ADMIN));

export const isSiteAdmin = (userRoles) =>
  userRoles && userRoles.some((role) => role.split(', ').includes(SITE_ADMIN));

export const isAdmin = (user) => {
  return canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    user
  );
};

export const canAccess = (roles, currentUser) => {
  const userRoles = currentUser?.roles?.map((userRole) =>
    userRole.toLowerCase()
  );

  // If the user does not have roles and we need to return false.
  // There is a case where the author does not have roles.
  if (!userRoles) {
    return false;
  }

  const checkRole = (role) => {
    return userRoles.includes(role.toLowerCase());
  };

  if (!roles.some(checkRole)) return false;

  return true;
};
