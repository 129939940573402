import React from 'react';
import classNames from 'classnames/bind';
import Button from 'koba/components/Button';
import { Icon } from '@thinkific/toga-react';

import { useTranslation } from 'react-i18next';
import useEventInterest from 'components/Events/hooks/useEventInterest';

import styles from './EventInterestedButton.module.scss';

const cx = classNames.bind(styles);
interface EventInterestedButtonProps {
  isInterested: boolean;
  isDisabled?: boolean;
  eventId: string;
}

const EventInterestedButton: React.FC<EventInterestedButtonProps> = ({
  eventId,
  isInterested,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const { addInterest, removeInterest, loading } = useEventInterest(eventId);

  const handleInterestButton = async () => {
    if (isInterested) {
      removeInterest();
    } else {
      addInterest();
    }
  };

  return (
    <Button
      appearance="default"
      className={cx('event-panel__interested-button', {
        interested: isInterested,
        'not-yet-interested': !isInterested,
      })}
      dataQA="event-panel__interested-button"
      isDisabled={loading || isDisabled}
      onClick={handleInterestButton}
    >
      <span className={cx('event-panel__interested-button-container')}>
        <span className={cx('event-panel__interested-button-icon-container')}>
          <Icon
            className={cx('event-panel__interested-button-icon')}
            name={isInterested ? 'checkmark' : 'plus'}
          />
        </span>
        {t('components-eventPanel-interested', 'interested')}
      </span>
    </Button>
  );
};

export default EventInterestedButton;
