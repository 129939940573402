import { gql } from '@apollo/client';

import { POST_FRAGMENT } from 'utils/queries';

export const POST_LIST_QUERY = gql`
  query PostList(
    $communityId: ID!
    $numOfPosts: Int
    $endCursor: String
    $postOrderField: PostOrderField = PINNED_AT
    $filter: PostFilter
    $numberOfReactions: Int!
  ) {
    community(id: $communityId) {
      id
      posts(
        first: $numOfPosts
        after: $endCursor
        filter: $filter
        orderBy: { field: $postOrderField, direction: DESC }
      ) {
        edges {
          cursor
          node {
            ...PostFields
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
  ${POST_FRAGMENT}
`;

export const POST_QUERY = gql`
  query Post($communityId: ID!, $postId: ID!, $numberOfReactions: Int!) {
    community(id: $communityId) {
      id
      post(id: $postId) {
        ...PostFields
      }
    }
  }
  ${POST_FRAGMENT}
`;
