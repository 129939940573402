import React, { SyntheticEvent } from 'react';
import { Button, Caption } from '@thinkific/toga-react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Linkify from 'linkify-react';

import { A11YEvent, a11yOnClick } from 'helpers/interactions';
import { copyToClipboard } from 'utils/textHelpers';
import { Event } from 'types/event';
import { localeEventsDate, TIMEZONE_FORMAT } from 'helpers/dateHelpers';
import { rawId } from 'utils/rawId';
import { useCommunityContext } from 'components/CommunityContext';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import TimezoneTip from 'components/Events/components/TimezoneTip';

import styles from './EventContent.module.scss';

const cx = classNames.bind(styles);

interface EventContentProps {
  event: Event;
  parentComponent: string;
}

const EventContent: React.FC<EventContentProps> = ({
  event,
  parentComponent,
}) => {
  const { t } = useTranslation();
  const { currentUser, trackEvent } = useCommunityContext();

  const { profile, locale } = currentUser;
  const userTimezone = profile?.timezone || undefined;
  const isOnlineMeeting = !!event?.meetingUrl;

  const startTime = DateTime.fromISO(event?.startTime || '', {
    zone: userTimezone,
    locale,
  });
  const endTime = DateTime.fromISO(event?.endTime || '', {
    zone: userTimezone,
    locale,
  });

  const handleJoinMeeting = (
    e: SyntheticEvent,
    mixpanelEvent: TrackingEvent
  ) => {
    e.preventDefault();

    trackEvent(mixpanelEvent, {
      [TrackingProperty.LIVE_EVENT_ID]: rawId(event?.id || ''),
      [TrackingProperty.LIVE_EVENT_ID_ENCODED]: event?.id,
    });
    window.open(event?.meetingUrl || '', '_blank');
  };

  const renderFormattedUserLocalTime = () => {
    const date = localeEventsDate(
      event?.startTime || '',
      locale,
      userTimezone,
      {
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      }
    );

    return (
      <div className={cx('event-content__date')}>
        <span>{date}</span>
        <br />
        <span data-qa="event-content__time">
          {startTime.toFormat('h:mm a')} - {endTime.toFormat(TIMEZONE_FORMAT)}
        </span>
      </div>
    );
  };

  const renderEventLocation = () => {
    if (event?.meetingUrl) {
      return (
        <div className={cx('event-content__meeting')}>
          <Button
            appearance="accessible-link"
            aria-label={t(
              'components-events-eventPanel-access_meeting',
              `Click to join the meeting. The meeting will be opened in a new window.`
            )}
            className={cx('event-content__meeting-url')}
            data-qa="event-content__meeting-url"
            href={event.meetingUrl}
            target="_blank"
            {...a11yOnClick<HTMLAnchorElement>(
              (e: A11YEvent<HTMLAnchorElement>) =>
                handleJoinMeeting(
                  e,
                  TrackingEvent.CLICK_LIVE_EVENT_MEETING_LINK
                )
            )}
          >
            {event.meetingUrl}
          </Button>

          <Button
            appearance="accessible-link"
            className={cx('event-content__meeting-button')}
            data-qa="event-content__copy-link"
            type="button"
            onClick={handleCopyLink}
          >
            {t('common-copy_link', 'copy link')}
          </Button>
        </div>
      );
    }

    return <span data-qa="event-content__location">{event?.location}</span>;
  };

  const handleCopyLink = () => {
    copyToClipboard(
      event?.meetingUrl || '',
      t(
        'components-events-eventPanel-copy_to_clipboard_success',
        'You have successfully copied the link to join the event to your clipboard.'
      )
    );
    trackEvent(TrackingEvent.CLICK_COPY_LIVE_EVENT_LINK, {
      [TrackingProperty.LIVE_EVENT_ID]: rawId(event?.id || ''),
      [TrackingProperty.LIVE_EVENT_ID_ENCODED]: event?.id,
      [TrackingProperty.COMPONENT]: parentComponent,
    });
  };

  return (
    <div className={cx('event-content__wrapper')}>
      <h4>
        {t('components-events-eventPanel-DATE_AND_TIME', 'DATE AND TIME')}
      </h4>
      <div>{renderFormattedUserLocalTime()}</div>
      <Caption className={cx('d-inline-block')}>
        <TimezoneTip />
      </Caption>
      <h4>
        {isOnlineMeeting
          ? t('components-events-eventPanel-EVENT_LINK', 'EVENT LINK')
          : t('components-events-eventPanel-EVENT_LOCATION', 'EVENT LOCATION')}
      </h4>
      <div>{renderEventLocation()}</div>

      <h4>{t('components-events-eventPanel-ABOUT', 'ABOUT')}</h4>
      <Linkify
        options={{
          target: { url: '_blank', email: '_blank' },
        }}
      >
        <div className={cx('event-content__about-wrapper')}>
          <pre
            className={cx('event-content__about')}
            data-qa="event-content__about"
          >
            {event?.description}
          </pre>
        </div>
      </Linkify>
    </div>
  );
};

export default EventContent;
