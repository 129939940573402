import { gql } from '@apollo/client';

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateLiveEvent($input: CreateLiveEventInput!) {
    createLiveEvent(input: $input) {
      userErrors {
        code
        message
      }
      liveEvent {
        id
      }
    }
  }
`;

export const DUPLICATE_EVENT_MUTATION = gql`
  mutation DuplicateLiveEvent($input: DuplicateLiveEventInput!) {
    duplicateLiveEvent(input: $input) {
      userErrors {
        code
        message
      }
      liveEvent {
        id
      }
    }
  }
`;

export const UPDATE_LIVE_EVENT_MUTATION = gql`
  mutation UpdateLiveEvent($input: UpdateLiveEventInput!) {
    updateLiveEvent(input: $input) {
      liveEvent {
        id
      }
      userErrors {
        code
        message
      }
    }
  }
`;

export const DELETE_LIVE_EVENT_MUTATION = gql`
  mutation DeleteLiveEvent($input: DeleteLiveEventInput!) {
    deleteLiveEvent(input: $input) {
      userErrors {
        code
        message
      }
    }
  }
`;

export const CREATE_ATTENDANCE_MUTATION = gql`
  mutation CreateAttendance($input: CreateAttendanceInput!) {
    createAttendance(input: $input) {
      userErrors {
        code
        message
      }
      liveEvent {
        id
        isInterested
        interestedUsers {
          totalCount
        }
      }
    }
  }
`;

export const DELETE_ATTENDANCE_MUTATION = gql`
  mutation DeleteAttendance($input: DeleteAttendanceInput!) {
    deleteAttendance(input: $input) {
      userErrors {
        code
        message
      }
      liveEvent {
        id
        isInterested
        interestedUsers {
          totalCount
        }
      }
    }
  }
`;
