import React from 'react';
import Button from 'koba/components/Button';
import { useTranslation } from 'react-i18next';

import { SpaceDetailsCard } from 'components/SpaceDetails';
import { useCommunityContext } from 'components/CommunityContext';
import TrackingEvent, {
  TrackingProperty,
  ProductTypes,
} from 'constants/trackingEvents';
import { rawId } from 'utils/rawId';

interface CourseCardProps {
  className?: string;
  percentageCompleted?: number | null;
  courseURL?: string;
  dataQA?: string;
  description?: string;
  imageUrl?: string;
  name: string;
  productId: string | null;
}

const CourseCard: React.FC<CourseCardProps> = ({
  className,
  percentageCompleted,
  courseURL,
  dataQA,
  description,
  imageUrl,
  name,
  productId,
}) => {
  const { t } = useTranslation();
  const { community, trackEvent } = useCommunityContext();
  let courseLinkText = t('RESUME COURSE');

  if (percentageCompleted === 0) {
    courseLinkText = t('START COURSE');
  } else if (percentageCompleted === 1) {
    courseLinkText = t('REPLAY COURSE');
  } else if (percentageCompleted === null) {
    courseLinkText = t('PREVIEW COURSE');
  }

  const onClick = () => {
    trackEvent(TrackingEvent.GO_TO_COURSE_FROM_COMMUNITY, {
      [TrackingProperty.PRODUCT_ID]: rawId(productId || ''),
      [TrackingProperty.PRODUCT_TYPE]: ProductTypes.COURSE,
    });
  };

  return (
    <SpaceDetailsCard
      className={className}
      dataQA={dataQA}
      description={description}
      footerAccessory={
        <Button
          appearance="default"
          color={community.primaryColor}
          dataQA="course-card__access-course"
          href={courseURL}
          rel="noopener"
          onClick={courseURL ? onClick : undefined}
        >
          {courseLinkText}
        </Button>
      }
      imageUrl={imageUrl}
      name={name}
    />
  );
};

export default CourseCard;
