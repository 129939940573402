import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  createHttpLink,
  from,
  gql,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import { features } from 'constants/featureFlags';
import { useCommunityContext } from 'components/CommunityContext';
import { isTextDark } from 'utils/colors';
import { isSiteAdmin } from 'utils/userHelpers';
import { rawId } from 'utils/rawId';

import styles from './NotificationPane.module.scss';

const cx = classNames.bind(styles);

const deps: Record<string, unknown> = {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  createHttpLink,
  from,
  gql,
  onError,
  relayStylePagination,
};

interface NotificationPaneProps {
  className?: string;
  dataQA?: string;
}

export function NotificationPane({
  className,
  dataQA,
}: NotificationPaneProps): ReturnType<React.FC> {
  const isSetupSuccessful = useSetup();
  const {
    community: { primaryColor },
    currentUser: { id: userId, roles, locale },
    site: { id: tenantId, trackingKey },
  } = useCommunityContext();

  const isAdmin = isSiteAdmin(roles);
  const hostRef = useRef<HTMLDivElement>(null);
  const paneRef = useRef<NotificationPane | null>(null);
  const currentUser: CurrentUser = {
    isAdmin,
    locale: locale || 'en',
    userId: rawId(userId),
    tenantId: rawId(tenantId),
    trackingKey: trackingKey || null,
  };

  useEffect(() => {
    const cleanup = () => {
      if (paneRef.current !== null) paneRef.current.destroy();
    };
    if (!isSetupSuccessful) return cleanup;
    if (hostRef.current === null) return cleanup;

    const NotificationPaneConstructor = grabNotificationPane();
    if (!NotificationPaneConstructor) return cleanup;
    const initializeNotificationPane = () => {
      if (!hostRef.current) return;
      paneRef.current = new NotificationPaneConstructor({
        wrapper: hostRef.current,
        currentUser,
        deps,
      });
    };
    let analyticsPromise: Promise<unknown> = Promise.resolve(null);
    if (trackingKey) {
      analyticsPromise = import('@thinkific/analytics').then(
        ({ default: ThinkificAnalytics }) => {
          deps.analytics = ThinkificAnalytics;
        }
      );
    }
    analyticsPromise.then(initializeNotificationPane);
    return cleanup;
  }, [isSetupSuccessful, isAdmin]);
  if (!isSetupSuccessful) return null;
  const conditionalClassNames = {
    'notification-pane--dark': isTextDark(primaryColor),
  };
  return (
    <div
      className={cx(className, 'notification-pane', conditionalClassNames)}
      data-qa={dataQA}
      ref={hostRef}
    />
  );
}

export function useIsNotificationPaneEnabled(): boolean {
  const { featureFlags = {} } = useCommunityContext();
  const { [features.NOTIFICATION_PANE]: isEnabled } = featureFlags;
  return isEnabled && !!grabNotificationPaneJs();
}

function useSetup(): boolean {
  const [isFinished, setIsFinished] = useState(!!grabNotificationPane());
  const { trackEvent } = useCommunityContext();
  useEffect(() => {
    if (grabNotificationPane()) {
      setIsFinished(true);
      return;
    }
    NotificationPane.loadScript(grabNotificationPaneJs())
      .then(() => setIsFinished(true))
      .catch((e) => {
        const data = { errorMsg: `${e?.msg || e}` };
        // @ts-ignore I think we should log an error here instead of a mixpanel event?
        trackEvent('Notification Pane script fails to load', data);
      });
  }, [trackEvent]);
  return isFinished;
}

let scriptLoadingPromise: Promise<unknown> | null = null;

NotificationPane.loadScript = function loadScript(
  src: string
): NonNullable<typeof scriptLoadingPromise> {
  if (scriptLoadingPromise !== null) return scriptLoadingPromise;
  scriptLoadingPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = reject;
    script.src = src;
    document.body.append(script);
  });
  return scriptLoadingPromise;
};

function grabNotificationPane(): NotificationPaneClass | undefined {
  return window.Thinkific?.NotificationPane;
}

function grabNotificationPaneJs() {
  return process.env.NOTIFICATION_PANE_JS || '';
}

interface CurrentUser {
  isAdmin: boolean;
  locale: string;
  userId: ReturnType<typeof rawId>;
  tenantId: ReturnType<typeof rawId>;
  trackingKey: string | null;
}

interface NotificationPane {
  destroy(): void;
}

interface NotificationPaneClassArgs {
  wrapper: HTMLElement;
  currentUser: CurrentUser;
  deps: typeof deps;
}

interface NotificationPaneClass {
  new (args: NotificationPaneClassArgs): NotificationPane;
}
