import { gql } from '@apollo/client';

export const PRODUCTS_EDGE_FRAGMENT = gql`
  fragment ProductsEdgeFields on ProductEdge {
    node {
      id
      publishedStatus
      productable {
        __typename
        ... on Course {
          ...CourseFields
        }
      }
      communityProductRelation {
        id
        relation {
          __typename
          ... on CommunitySpace {
            id
            name
            community {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_FRAGMENT = gql`
  fragment ProductsFields on ProductConnection {
    edges {
      ...ProductsEdgeFields
    }
  }
  ${PRODUCTS_EDGE_FRAGMENT}
`;
