import React from 'react';
import classNames from 'classnames/bind';

import { Skeleton } from '@thinkific/toga-react';

import styles from './SkeletonSpacesList.module.scss';

const cx = classNames.bind(styles);

const SkeletonSpacesList: React.FC = () => {
  return (
    <div className={cx('spaces-list-skeleton__content')}>
      <Skeleton height="12px" width="40%" />
      <Skeleton height="8px" width="80%" />
      <Skeleton height="8px" width="80%" />
      <Skeleton height="8px" width="80%" />
    </div>
  );
};

export default SkeletonSpacesList;
