import React from 'react';
import { useTranslation } from 'react-i18next';
import { routes } from 'utils/routerHelpers';

interface TimezoneTipProps {
  onClick?: (e) => void;
}

const TimezoneTip: React.FC<TimezoneTipProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div>
      {t(
        'components-events-timezone_tip_text',
        'You can edit your preferred timezone in your Profile.'
      )}{' '}
      <a href={routes.myAccount} onClick={onClick}>
        {t('components-events-timezone_tip_edit', 'Edit')}
      </a>
    </div>
  );
};

export default TimezoneTip;
