import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Button, Icon } from '@thinkific/toga-react';

import CommunityActions from 'components/CommunityActions';
import { useCommunityContext } from 'components/CommunityContext';
import { canAccess } from 'utils/userHelpers';
import MobileMenuUserSection from 'components/MobileMenuUserSection';
import MobileMenu from 'koba/components/MobileMenu';
import { SPACE_QUERY } from 'schema/Space/queries';
import {
  goToEditSpace,
  pathnames,
  goToPostListPage,
} from 'utils/routerHelpers';
import { SpaceDetailsCard } from 'components/SpaceDetails';
import MenuTab from 'koba/components/MobileMenu/components/MenuTab';
import { useWindowSize } from 'hooks/useWindowSize';
import { SCREEN_LG, USER_ROLES } from 'utils/constants';
import { features } from 'constants/featureFlags';
import CourseDetails from 'components/CourseCard/CourseDetails';

import styles from './MobileNav.module.scss';

const cx = classNames.bind(styles);

interface MobileNavProps {
  community: {
    name: string;
    id: string;
    description: string;
    imageUrl?: string;
  };
  isPostView: boolean;
}

const MobileNav: React.FC<MobileNavProps> = ({ community, isPostView }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRightAccessoryOpen, setIsRightAccessoryOpen] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();
  const { currentUser, featureFlags } = useCommunityContext();
  const spaceId = router.query?.spaceId as string;
  const spaceDetailTabPages = [pathnames.spaces.space];
  const showSpaceDetailsTab = spaceDetailTabPages.includes(router.pathname);
  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];

  const { width } = useWindowSize();

  useEffect(() => {
    if (width > SCREEN_LG) {
      handleCloseMenu();
      handleCloseRightAccessory();
    }
  }, [width]);

  const headerTitleByPath = {
    [pathnames.home]: t('pages-home_title', 'Home'),
    [pathnames.following]: t('pages-following_title', 'Following'),
    [pathnames.spaces.new]: t('pages-new_space_title', 'Create Space'),
    [pathnames.spaces.edit]: t('pages-edit_space_title', 'Space Settings'),
    [pathnames.events.new]: t('pages-new_event_title', 'Create Event'),
    [pathnames.events.events]: t('pages-events_title', 'Events'),
    [pathnames.events.event]: t('pages-event_title', 'Event'),
    [pathnames.events.edit]: t('pages-edit_event_title', 'Edit Event'),
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleOpenMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseRightAccessory = () => {
    setIsRightAccessoryOpen(false);
  };

  const handleOpenRightAccessory = () => {
    setIsRightAccessoryOpen(true);
  };

  const { loading, data } = useQuery(SPACE_QUERY, {
    variables: {
      communityId: community.id,
      spaceId,
      isCoursesToCommunitiesEnabled,
    },
    notifyOnNetworkStatusChange: true,
    skip: !spaceId,
  });

  const space = data?.community?.communitySpace;

  let headerTitle = [pathnames.events.events, pathnames.events.event].includes(
    router.pathname
  ) ? (
    <span className={cx('mobile-nav__title')}>
      {headerTitleByPath[router.pathname]}
    </span>
  ) : (
    <span>{headerTitleByPath[router.pathname]}</span>
  );
  if (showSpaceDetailsTab && space?.name) {
    headerTitle = <span>{space?.name}</span>;
  } else if (isPostView) {
    headerTitle = <span>{community?.name}</span>;
  }

  const goToEditSpacePage = (event: Event) => {
    event.preventDefault();
    handleCloseRightAccessory();
    goToEditSpace(router, spaceId, community.id);
  };

  const spaceDetailsFooter = (
    <>
      <hr className={cx('mobile-nav__separator')} />
      <Button
        appearance="knockout"
        className={cx('mobile-nav__button')}
        href={`/communities/${community.id}/spaces/${spaceId}/edit/`}
        type="button"
        onClick={goToEditSpacePage}
      >
        <Icon
          name="cog"
          title={t('pages-edit_space_title', 'Space Settings')}
        />
        {t('pages-edit_space_title', 'Space Settings')}
      </Button>
    </>
  );

  const canManageSpace = canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    currentUser
  );

  return (
    <>
      <MobileMenu
        goToPostListPage={goToPostListPage(router, community.id)}
        headerTitle={loading ? '' : headerTitle}
        isEventPage={pathnames.events.event === router.pathname}
        isOpen={isMenuOpen}
        isPostView={isPostView}
        menuTitle={community?.name}
        rightAccessory={
          showSpaceDetailsTab && (
            <MenuTab
              iconName="circle-outline-info"
              isOpen={isRightAccessoryOpen}
              menuTitle={t('Details')}
              size="small"
              slideTo="left"
              title={t("Click to see this Space's information")}
              hasOpenButton
              hasOverlay
              onClose={handleCloseRightAccessory}
              onOpen={handleOpenRightAccessory}
            >
              <SpaceDetailsCard
                description={space?.description}
                icon={space?.iconName}
                imageAltText={space?.imageAltText}
                imageUrl={space?.imageUrl}
                name={space?.name}
              />
              {isCoursesToCommunitiesEnabled && <CourseDetails />}
              {canManageSpace && spaceDetailsFooter}
            </MenuTab>
          )
        }
        onClose={handleCloseMenu}
        onOpen={handleOpenMenu}
      >
        <CommunityActions handleMobileNavClick={handleCloseMenu} />
        <MobileMenuUserSection />
      </MobileMenu>
    </>
  );
};

export default MobileNav;
