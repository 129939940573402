import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledOpacity = styled.div`
  opacity: ${(props) => props.value};
`;

interface OpacityProps {
  children: ReactNode;
  value: number;
}

const Opacity: React.FC<OpacityProps> = ({ children, value }) => {
  return <StyledOpacity value={value}>{children}</StyledOpacity>;
};

export default Opacity;
