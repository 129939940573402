import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    fullName
    firstName
    avatarUrl
    roles
  }
`;

export const MENTIONED_USERS_FRAGMENT = gql`
  fragment MentionedUsersField on UserConnection {
    edges {
      node {
        id
        fullName
        avatarUrl
      }
    }
  }
`;

export const POST_ATTACHMENTS_FRAGMENT = gql`
  fragment PostAttachmentsFields on PostAttachment {
    attachmentType
    fileName
    fileSizeInBytes
    id
    url
    altText
  }
`;

export const POST_FRAGMENT = gql`
  fragment PostFields on Post {
    id
    title
    content
    createdAt
    pinnedAt
    editedAt
    isFollowing
    hasReacted
    communitySpace {
      id
      name
      iconName
    }
    postAttachments {
      ...PostAttachmentsFields
    }
    replies @connection(key: "replies") {
      totalCount
      nodes {
        id
        replies {
          nodes {
            id
          }
          totalCount
        }
      }
    }
    mentionedUsers @connection(key: "mentionedUsers") {
      ...MentionedUsersField
    }
    postReactions(first: $numberOfReactions) @connection(key: "postReactions") {
      totalCount
      edges {
        node {
          user {
            id
            fullName
          }
        }
      }
    }
    user {
      ...UserFields
    }
  }
  ${USER_FRAGMENT}
  ${MENTIONED_USERS_FRAGMENT}
  ${POST_ATTACHMENTS_FRAGMENT}
`;

export const REPLY_FRAGMENT = gql`
  fragment ReplyFields on Reply {
    content
    createdAt
    editedAt
    isFollowing
    id
    postReactions(first: $numberOfReactions) @connection(key: "postReactions") {
      totalCount
      edges {
        node {
          user {
            id
            fullName
          }
        }
      }
    }
    mentionedUsers @connection(key: "mentionedUsers") {
      ...MentionedUsersField
    }
    user {
      ...UserFields
    }
    hasReacted
    postAttachments {
      id
      altText
      attachmentType
      fileName
      fileSizeInBytes
      url
    }
  }
  ${USER_FRAGMENT}
  ${MENTIONED_USERS_FRAGMENT}
`;

export const REPLY_EDGE_NODE_REPLIES_FRAGMENT = gql`
  fragment ReplyEdgeNodeReliesFields on ReplyConnection {
    edges {
      cursor
      node {
        ...ReplyFields
      }
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
    totalCount
  }
  ${REPLY_FRAGMENT}
`;

export const REPLY_EDGE_FRAGMENT = gql`
  fragment ReplyEdgeFields on Reply {
    ...ReplyFields
    replies(
      last: $numOfNestedReplies
      orderBy: { field: CREATED_AT, direction: ASC }
    ) {
      ...ReplyEdgeNodeReliesFields
    }
  }
  ${REPLY_FRAGMENT}
  ${REPLY_EDGE_NODE_REPLIES_FRAGMENT}
`;

export const MAIN_QUERY = gql`
  query Main($communityId: ID!) {
    site {
      id
      favicon
      filestackPolicy {
        policy
        signature
      }
      featureFlags
      subdomain
      trackingKey
      whiteLabellingActivated
      googleAnalyticsKey
      appcuesDisabled
      integrations {
        facebookPixelCode
        gaMeasurementId
      }
    }

    community(id: $communityId) {
      id
      name
      description
      primaryColor
      font
      imageUrl
      s3UploadPath
      published
      status
      communityUsers(filter: { active: true }) {
        totalCount
      }
      mentionableUsers(searchTerm: "", first: 5) {
        edges {
          node {
            id
            user {
              id
              fullName
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
    }

    me {
      id
      fullName
      email
      avatarUrl
      roles
      locale
      profile {
        id
        timezone
      }
    }
  }
`;

export const ACCOUNT_NOTIFICATIONS_QUERY = gql`
  query AccountNotifications {
    accountNotifications {
      isEnabled
    }
  }
`;
