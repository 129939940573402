import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { relativeFormattedDateTime } from 'helpers/dateHelpers';
import UserAvatar from 'components/UserAvatar';
import { goToPostPage } from 'utils/routerHelpers';
import { useCommunityContext } from 'components/CommunityContext';

import TrackingEvent from 'constants/trackingEvents';
import styles from './ProfilePost.module.scss';

const cx = classNames.bind(styles);

interface ProfilePostProps {
  author: {
    firstName: string;
    fullName: string;
    id: string;
    avatarUrl?: string;
  };
  createdAt: string;
  title: string;
  id: string;
  onPostClick: () => void;
}

const ProfilePost: React.FC<ProfilePostProps> = ({
  author,
  createdAt,
  title,
  id,
  onPostClick,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { community, trackEvent, currentUser } = useCommunityContext();

  const postRef = useRef(null);
  const time = relativeFormattedDateTime(createdAt, currentUser?.locale, t);

  const handlePostClick = () => {
    goToPostPage(router, community.id, id);

    trackEvent(TrackingEvent.PAST_ACTIVITY_CLICKED, {});
    onPostClick();
  };
  const postedText = t('{{name}} posted {{time}}', {
    name: author.firstName,
    time,
  });

  return (
    <li className={cx('profile-post')}>
      <span className={cx('profile-post__label')} data-qa="profile-post__label">
        <span title={postedText}>{postedText}</span>
        <div className={cx('profile-post__details')}>
          <UserAvatar
            avatarUrl={author?.avatarUrl}
            className={cx('profile-post__avatar')}
            size="sm"
            srcSet={`${author?.avatarUrl}?width=40 1x, ${author?.avatarUrl}?width=40&dpr=2 2x, ${author?.avatarUrl}?width=40&dpr=3 3x`}
            userId={author.id}
            username={author.fullName}
          />
          <div
            aria-label={t('Read full post: {{title}}', { title })}
            className={cx('profile-post__title')}
            data-qa="profile-post__title"
            ref={postRef}
            role="button"
            tabIndex={0}
            onClick={handlePostClick}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handlePostClick();
              }
            }}
          >
            <span>{title}</span>
          </div>
        </div>
      </span>
    </li>
  );
};

export default ProfilePost;
