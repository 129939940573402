import React, {
  forwardRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  MouseEvent,
} from 'react';
import classNames from 'classnames/bind';
import { isTextDark } from 'utils/colors';

import styles from './Button.module.scss';

type withIconType = (posInput: {
  position: string;
}) => <P>(
  comp: ForwardRefExoticComponent<P>
) => React.FC<P & { className?: string }>;

// This could be simplified...
export const withIcon: withIconType = ({ position }) => {
  return (WrappedComponent) => {
    return function MyFunc(props) {
      const { className } = props;
      const classes = cx(className, {
        'button--icon-left': position === 'left',
        'button--icon-right': position === 'right',
        'button--icon-both': position === 'both',
        'button--icon-only': position === 'none',
      }) as string;

      const newProps = { ...props, className: classes };
      return <WrappedComponent {...newProps} />;
    };
  };
};

const cx = classNames.bind(styles);

export interface ButtonProps {
  appearance?: 'default' | 'ghost' | 'knockout' | 'link' | 'block';
  ariaLabel?: string;
  children: React.ReactNode;
  className?: string | string[];
  color?: string;
  dataQA?: string;
  hasDanger?: boolean;
  href?: string;
  isActive?: boolean;
  isBlockStyle?: boolean;
  isDisabled?: boolean;
  size?: 'x-small' | 'small';
  type?: 'button' | 'submit';
  onClick?: (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  id?: string;
  target?: '_blank';
  rel?: 'noopener';
  download?: string;
}

const Button: ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps
> = (
  {
    appearance = 'default',
    ariaLabel,
    children,
    color = null,
    className = null,
    dataQA = null,
    hasDanger = false,
    isActive = false,
    isBlockStyle = false,
    isDisabled = false,
    href,
    onClick,
    size = null,
    type = 'button',
    ...props
  },
  ref
) => {
  const Element = href ? 'a' : 'button';
  const role = Element === 'a' ? 'button' : undefined;

  const baseClasses = cx(className, 'button', {
    'button--x-small': size === 'x-small',
    'button--small': size === 'small',
    active: isActive === true,
    'button--dark-text': color && isTextDark(color),
  });

  const appearanceClasses = cx({
    'button--default': appearance === 'default',
    'button--default-danger': appearance === 'default' && hasDanger,
    'button--ghost': appearance === 'ghost',
    'button--ghost-danger': appearance === 'ghost' && hasDanger,
    'button--knockout': appearance === 'knockout',
    'button--knockout-danger': appearance === 'knockout' && hasDanger,
    'button--link': appearance === 'link',
    'button--block': isBlockStyle,
  });

  if (href) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <a
        aria-label={ariaLabel}
        className={`${baseClasses} ${appearanceClasses}`}
        data-qa={dataQA}
        href={href}
        ref={ref as React.LegacyRef<HTMLAnchorElement> | undefined}
        role={role}
        style={color ? { backgroundColor: color } : {}}
        onClick={onClick}
        {...props}
      >
        <span>{children}</span>
      </a>
    );
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      aria-label={ariaLabel}
      className={`${baseClasses} ${appearanceClasses}`}
      data-qa={dataQA}
      disabled={isDisabled}
      ref={ref as React.LegacyRef<HTMLButtonElement> | undefined}
      role={role}
      style={color ? { backgroundColor: color } : {}}
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
};

export const ButtonWithIconLeft = withIcon({ position: 'left' })(
  forwardRef(Button)
);
export const ButtonWithIconOnly = withIcon({ position: 'none' })(
  forwardRef(Button)
);
export const ButtonWithIconRight = withIcon({ position: 'right' })(
  forwardRef(Button)
);

export default forwardRef(Button);
