import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';

import styles from './PageSkeleton.module.scss';

const cx = classNames.bind(styles);

const PageSkeleton: React.FC = () => {
  return (
    <div className={cx('page-skeleton__wrapper')}>
      <div className={cx('page-skeleton__navbar')}>
        <SkeletonLine height="24px" width="160px" />
        <SkeletonLine height="24px" width="160px" />
        <SkeletonLine height="24px" width="160px" />
      </div>

      <div className={cx('page-skeleton__main')}>
        <div className={cx('page-skeleton__aside')}>
          <div className={cx('page-skeleton__aside-first-block')}>
            <SkeletonLine height="40px" width="100%" />
            <SkeletonLine height="40px" width="100%" />
          </div>
          <div className={cx('page-skeleton__aside-second-block')}>
            <SkeletonLine height="16px" width="70px" />
            <SkeletonLine height="40px" width="100%" />
            <SkeletonLine height="40px" width="100%" />
            <SkeletonLine height="40px" width="100%" />
          </div>
        </div>
        <div className={cx('page-skeleton__content')}>
          <div className={cx('page-skeleton__content-first-element')}>
            <SkeletonLine height="160px" width="100%" />
            <div className={cx('page-skeleton__content-first-element-body')}>
              <SkeletonLine height="8px" width="209px" />
              <SkeletonLine height="8px" width="100%" />
              <SkeletonLine height="8px" width="100%" />
            </div>
          </div>
          <SkeletonLine height="16px" width="70px" />
          <div className={cx('page-skeleton__content-empty-block')} />
          <div className={cx('page-skeleton__content-empty-block')} />
        </div>
        <div className={cx('page-skeleton__info')}>
          <div className={cx('page-skeleton__info-community')}>
            <SkeletonLine height="16px" width="60%" />
            <div className={cx('page-skeleton__info-community-container')}>
              <div className={cx('page-skeleton__info-community-post')}>
                <div
                  className={cx(
                    'page-skeleton__info-community-container-avatar'
                  )}
                />
                <div
                  className={cx('page-skeleton__info-community-container-body')}
                >
                  <SkeletonLine width="60px" />
                  <SkeletonLine width="120px" />
                </div>
              </div>
              <div className={cx('page-skeleton__info-community-post')}>
                <div
                  className={cx(
                    'page-skeleton__info-community-container-avatar'
                  )}
                />
                <div
                  className={cx('page-skeleton__info-community-container-body')}
                >
                  <SkeletonLine width="60px" />
                  <SkeletonLine width="120px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSkeleton;
