import Backend from 'i18next-http-backend';

const backend = new Backend(null, {
  loadPath: '/translations/communities.json',
});

export default {
  i18n: {
    defaultLocale: 'en',
    locales: ['en'],
  },
  use: [backend],
  serializeConfig: false,
  nsSeparator: false,
  keySeparator: false,
  compatibilityJSON: 'v3',
};
