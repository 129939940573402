import { MouseEvent, KeyboardEvent } from 'react';

export type A11YEvent<T> = KeyboardEvent<T> | MouseEvent<T>;

type AccessibleOnClick = <T>(
  onClick: (e: KeyboardEvent<T> | MouseEvent<T>) => void,
  tabIndex?: number
) => {
  role: string;
  tabIndex: number;
  onKeyPress(e: KeyboardEvent<T>): void;
  onClick(e: MouseEvent<T>): void;
};

export const a11yOnClick: AccessibleOnClick = (onClick, tabIndex?: number) => {
  return {
    role: 'button',
    tabIndex: tabIndex || 0,
    onKeyPress: (e) => {
      if (e.type === 'click' || e.key === 'Enter' || e.key === ' ') {
        onClick(e);
      }
    },
    onClick,
  };
};
