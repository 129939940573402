import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { NextRouter } from 'next/router';
import { SyntheticEvent } from 'react';
import { TrackEvent } from 'types/analytics';
import { rawId } from 'utils/rawId';
import { goToEditEvent } from 'utils/routerHelpers';

export const handleJoinMeeting = (
  e: SyntheticEvent,
  mixpanelEvent: TrackingEvent,
  trackEvent: TrackEvent,
  liveEventId: string,
  liveEventUrl: string,
  parentComponent: string
): void => {
  e.preventDefault();

  trackEvent(mixpanelEvent, {
    [TrackingProperty.LIVE_EVENT_ID]: rawId(liveEventId || ''),
    [TrackingProperty.LIVE_EVENT_ID_ENCODED]: liveEventId,
    [TrackingProperty.COMPONENT]: parentComponent,
  });
  window.open(liveEventUrl || '', '_blank');
};

export const handleEditEvent = (
  e: SyntheticEvent,
  router: NextRouter,
  trackEvent: TrackEvent,
  communityId: string,
  liveEventId: string,
  parentComponent: string
): void => {
  e?.preventDefault();

  trackEvent(TrackingEvent.CLICK_EDIT_LIVE_EVENT, {
    [TrackingProperty.LIVE_EVENT_ID]: rawId(liveEventId || ''),
    [TrackingProperty.LIVE_EVENT_ID_ENCODED]: liveEventId,
    [TrackingProperty.COMPONENT]: parentComponent,
  });

  goToEditEvent(router, liveEventId, communityId);
};
