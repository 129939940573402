import React, { useEffect, useState, MouseEvent, KeyboardEvent } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IconButton, Tooltip } from '@thinkific/toga-react';

import { SectionToggle } from 'koba/components/Section/SectionToggle';
import { useCommunityContext } from 'components/CommunityContext';
import { goToNewSpace, goToSpaces } from 'utils/routerHelpers';
import { Space } from 'interfaces/space';
import { SECTION_QUERY } from 'schema/Section/queries';
import { SPACE_QUERY } from 'schema/Space/queries';
import { REORDER_SPACE_MUTATION } from 'schema/Space/mutations';
import { genericToastAlert } from 'utils/toastHelpers';

import { USER_ROLES } from 'utils/constants';
import { canAccess } from 'utils/userHelpers';
import { rawId } from 'utils/rawId';
import { features } from 'constants/featureFlags';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import styles from '../CommunityActionsList.module.scss';
import SpacePanel from './SpacePanel';
import SkeletonSpacesList from './SkeletonSpacesList';

const cx = classNames.bind(styles);

interface SpacesListProps {
  handleMobileNavClick?: () => void;
}

const SpacesList: React.FC<SpacesListProps> = ({ handleMobileNavClick }) => {
  const client = useApolloClient();
  const { t } = useTranslation();
  const {
    community,
    currentUser,
    featureFlags,
    trackEvent,
  } = useCommunityContext();
  const router = useRouter();

  const isCoursesToCommunitiesEnabled = !!featureFlags[
    features.COURSES_TO_COMMUNITIES
  ];

  const [spaceList, setSpaceList] = useState<Space[]>([]);
  const isDragEnabled = canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    currentUser
  );

  const handleClick = (
    event: MouseEvent | KeyboardEvent,
    filter: string,
    name: string
  ) => {
    event.preventDefault();
    if (handleMobileNavClick) handleMobileNavClick();

    trackEvent(TrackingEvent.SPACE_CLICKED, {
      [TrackingProperty.COMMUNITY_ID]: rawId(community.id),
      [TrackingProperty.SPACE_ID]: rawId(filter),
      [TrackingProperty.SPACE_NAME]: name,
    });

    goToSpaces(router, filter, community.id);
  };

  const { loading, data, error } = useQuery(SECTION_QUERY, {
    variables: {
      communityId: community.id,
      isCoursesToCommunitiesEnabled,
    },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      genericToastAlert();
    },
  });

  useEffect(() => {
    if (data) {
      const spaces: Space[] = [];
      data?.community?.communitySections?.edges?.forEach((section) => {
        section?.node?.communitySpaces?.edges?.forEach((space) => {
          // Performance Improvement
          // Since the SectionList query fetches all of the data required for the CommunitySpace query,
          // we can preload the apollo cache with that data. Any subsequent CommunitySpace query will
          // come from the cache instead of the server.
          client.writeQuery({
            query: SPACE_QUERY,
            data: {
              community: {
                __typename: 'Community',
                id: community.id,
                communitySpace: space.node,
              },
            },
            variables: {
              spaceId: space.node.id,
              communityId: community.id,
              isCoursesToCommunitiesEnabled,
            },
          });
          spaces.push({
            id: space.node.id,
            name: space.node.name,
            iconName: space.node?.iconName,
            isExclusive: space.node?.isExclusive,
          });
        });
      });
      setSpaceList(spaces);
    }
  }, [data]);

  let spaceGroups = [];

  if (data?.community) {
    spaceGroups = data.community?.communitySections?.edges;
  }

  const hasError = !!(
    error?.graphQLErrors?.length || data?.community?.userErrors?.length
  );

  const [reorderSpaceMutation, { loading: reorderLoading }] = useMutation(
    REORDER_SPACE_MUTATION,
    {
      onError: genericToastAlert,
    }
  );

  const onDragStart = (start, provided) => {
    provided.announce(
      t(
        'components-communityActionsList-space_drag_start_sr_message',
        'You have lifted the Space in position {{position}}. Use the arrows up and down to move the Space to a new position. Once you moved it to the desired position press the space bar to release the Space to its new position.',
        {
          position: start.source.index + 1,
        }
      )
    );
  };

  const onDragUpdate = (update, provided) => {
    const message = update.destination
      ? t(
          'components-communityActionsList-space_drag_update_sr_message',
          'You have moved the Space to position {{position}}. If this is the final position press the space bar to release it to this position.',
          {
            position: update.destination.index + 1,
          }
        )
      : t('You are currently not over a droppable area');

    provided.announce(message);
  };

  const onDragEnd = async (result, provided) => {
    const { destination, source, draggableId } = result;

    const message = destination
      ? t('You have moved the Space from position {{from}} to {{to}}', {
          from: source.index + 1,
          to: destination.index + 1,
        })
      : t(
          'The Space has been returned to its starting position of {{position}}',
          {
            position: source.index + 1,
          }
        );

    provided.announce(message);

    if (
      !destination ||
      (destination.droppableId === source.droppableId &&
        destination.index === source.index)
    ) {
      return;
    }

    const originalSpaces = [...spaceList];
    const spaces = [...spaceList];
    spaces.splice(destination.index, 0, ...spaces.splice(source.index, 1));
    setSpaceList([...spaces]);

    const { data: reorderSpaceData, errors } = await reorderSpaceMutation({
      variables: {
        input: { id: draggableId, position: destination.index },
      },
      refetchQueries: [
        {
          query: SECTION_QUERY,
          variables: {
            communityId: community.id,
            isCoursesToCommunitiesEnabled,
          },
        },
      ],
    });

    if (errors?.length || reorderSpaceData?.reorderCommunitySpace?.userErrors) {
      genericToastAlert();
      setSpaceList([...originalSpaces]);
    }
  };

  const handleCreateSpaceClick = () => {
    if (handleMobileNavClick) handleMobileNavClick();

    trackEvent(TrackingEvent.GO_TO_NEW_SPACE, {
      [TrackingProperty.COMPONENT]: 'SpacesList',
    });
    goToNewSpace(router, community.id);
  };

  const AddSpaceButton = canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    currentUser
  ) && (
    <Tooltip role="presentation" title={t('Create Space')}>
      <IconButton
        appearance="utility"
        aria-label={t('Add new Space')}
        className={cx('add-space__button')}
        data-qa="main-create-space__button"
        name="plus"
        title={t('Add new Space')}
        onClick={handleCreateSpaceClick}
      />
    </Tooltip>
  );

  return (
    <>
      {(hasError || loading) && <SkeletonSpacesList />}
      {!hasError && !loading && (
        <div
          className={cx('spaces-list__wrapper')}
          data-qa={
            isDragEnabled
              ? 'spaces-list__wrapper--draggable'
              : 'spaces-list__wrapper'
          }
        >
          {spaceGroups?.map(({ node: spaceGroup }: { node }) => {
            return (
              <SectionToggle
                caretType="fill"
                key={spaceGroup.id}
                rightAccessory={AddSpaceButton}
                t={t}
                title={t('SPACES')}
              >
                {/* eslint-disable no-shadow */}
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}
                  onDragUpdate={onDragUpdate}
                >
                  <Droppable droppableId={spaceGroup.id}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {spaceList.map((space, index) => (
                          <SpacePanel
                            handleClick={handleClick}
                            index={index}
                            isDragDisabled={!isDragEnabled || reorderLoading}
                            key={space.id}
                            space={space}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </SectionToggle>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SpacesList;
