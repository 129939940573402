import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames/bind';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const H1: FC<HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...props
}) => {
  const classes = cx('h1', className);

  return (
    <h1 className={classes} {...props}>
      {children}
    </h1>
  );
};

export default H1;
