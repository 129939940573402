// eslint-disable-next-line import/prefer-default-export
export const availableIcons = [
  'arrow-down',
  'arrow-grow',
  'arrow-left',
  'arrow-redo',
  'arrow-right',
  'arrow-shrink',
  'arrow-undo',
  'arrow-up-right',
  'arrow-up',
  'beta',
  'bookmark-fill',
  'bookmark-outline-add',
  'bookmark-outline',
  'bullet',
  'caret-fill-down',
  'caret-fill-left',
  'caret-fill-right',
  'caret-fill-up',
  'caret-stroke-down',
  'caret-stroke-left',
  'caret-stroke-right',
  'caret-stroke-up',
  'certificate',
  'checkbox-empty',
  'checkbox-indeterminate',
  'checkbox-selected',
  'checkmark',
  'circle-fill-check',
  'circle-fill-dash',
  'circle-fill-info',
  'circle-fill-x',
  'circle-outline-info',
  'circle-outline-play',
  'circle-outline-price',
  'circle-slash',
  'cog',
  'column-select',
  'connection',
  'content-assignment',
  'content-audio',
  'content-discussions',
  'content-download',
  'content-exam',
  'content-live',
  'content-multimedia',
  'content-pdf',
  'content-presentation',
  'content-quiz',
  'content-survey',
  'content-text',
  'content-video',
  'data-download',
  'data-export',
  'data-import',
  'discussions',
  'document',
  'download',
  'ellipsis-v',
  'enrollment-add',
  'enrollment-remove',
  'envelope',
  'eye-slash',
  'eye',
  'filter',
  'flag',
  'folder',
  'graph-bar',
  'handle-drag',
  'header',
  'heart-fill',
  'heart-outline',
  'icon-communities',
  'image',
  'integration',
  'key',
  'link',
  'location',
  'lock',
  'magnifying-glass',
  'menu',
  'message',
  'new-window',
  'page-duplicate',
  'page-new',
  'page',
  'pencil',
  'people',
  'pin',
  'plane',
  'play',
  'plus',
  'product-account',
  'product-blank',
  'product-blog',
  'product-checkmark-multicolor',
  'product-checkmark',
  'product-clipboard',
  'product-clock-small',
  'product-design',
  'product-digital-download',
  'product-feature-afterpurchase',
  'product-flagship',
  'product-gift',
  'product-handshake',
  'product-help',
  'product-logout',
  'product-manage',
  'product-market-sell',
  'product-microphone',
  'product-mini-course',
  'product-page',
  'product-play',
  'product-pre-sell',
  'product-preview',
  'product-resource-library',
  'product-search-initial',
  'product-search-no-result',
  'product-settings',
  'product-students',
  'product-updates',
  'product-upgrade',
  'product-webinar-replay',
  'radio-empty',
  'radio-selected',
  'record',
  'refresh',
  'reply',
  'shield',
  'social-facebook',
  'social-google',
  'social-linkedIn',
  'social-twitter',
  'speaker',
  'star-fill',
  'star-outline',
  'smiley-face',
  'stop-sign',
  'trash-can',
  'trophy',
  'upgrade',
  'user-login',
  'user-logout',
  'view-device-desktop',
  'view-device-phone',
  'view-grid',
  'view-list',
  'view-menu',
  'warning',
  'webhook',
  'window-maximize',
  'window-minimize',
  'x-dismiss',
];
