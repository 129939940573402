import { hexToRGB, isTextDark } from '../utils/colors';

export const setGlobalCSSVariables = (primaryColor) => {
  const [r, g, b] = hexToRGB(primaryColor);
  const textColor = isTextDark(primaryColor)
    ? getComputedStyle(document.documentElement).getPropertyValue('--dark-text')
    : getComputedStyle(document.documentElement).getPropertyValue(
        '--light-text'
      );

  document.documentElement.style.setProperty(
    '--primary-color',
    `${+r}, ${+g}, ${+b}`
  );

  document.documentElement.style.setProperty('--main-text-color', textColor);
};
