import React, { RefObject } from 'react';

export default function useClickOutside(
  elementRef: RefObject<HTMLElement>
): void {
  const ref = elementRef.current;

  React.useEffect(() => {
    if (ref) {
      ref?.addEventListener('transitionend', handleTransitionEnded, true);
      ref?.addEventListener('keydown', handleTabNavigation, true);
    }

    return () => {
      ref?.removeEventListener('transitionend', handleTransitionEnded, true);
      ref?.removeEventListener('keydown', handleTabNavigation, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const getFocusableElements = () => {
    const focusableElements = ref?.querySelectorAll<HTMLElement>(
      'select, input, textarea, button, a, svg[role="img"], div[role="button"], div[role="link"]'
    );

    if (focusableElements) {
      const elements = Array.from(focusableElements);

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      return { elements, firstElement, lastElement };
    }
    return {};
  };

  const handleTabNavigation = (event) => {
    const { lastElement, firstElement } = getFocusableElements();

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstElement) {
          event.preventDefault();
          lastElement?.focus();
        }
      } else if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement?.focus();
      }
    }
  };

  const handleTransitionEnded = (event) => {
    const { firstElement } = getFocusableElements();

    if (event.propertyName === 'visibility') firstElement?.focus();
  };
}
