import qs from 'query-string';
import { NextRouter } from 'next/router';

/* Pages within communities */
export const pathnames = {
  home: '/[communityId]',
  search: '/[communityId]/search',
  following: '/[communityId]/following',
  members: '/[communityId]/members',
  spaces: {
    new: '/[communityId]/spaces/new',
    space: '/[communityId]/spaces/[spaceId]',
    edit: '/[communityId]/spaces/[spaceId]/edit',
  },
  post: '/[communityId]/post/[postId]',
  events: {
    new: '/[communityId]/events/new',
    edit: '/[communityId]/events/[eventId]/edit',
    events: '/[communityId]/events',
    event: '/[communityId]/events/[eventId]',
  },
};

/* External routes */
export const routes = {
  myAccount: '/account',
  dashboard: '/enrollments',
  admin: '/manage',
  signOut: '/users/sign_out',
  users: '/manage/users',
  communities: '/manage/communities',
  courses: '/manage/courses',
  coursesPreview: '/manage/courses/preview',
  subscription: '/manage/subscription',
  publishCommunity: (communityId: string): string =>
    `/manage/communities/${communityId}/publish`,
};

export const getSettingsUrl = (communityId: string): string => {
  return `/manage/communities/${communityId}/settings`;
};

export const getPublishUrl = (communityId: string): string => {
  return `/manage/communities/${communityId}/publish`;
};

export const getPostUrl = (communityId: string, postId: string): string => {
  return `/communities/${communityId}/post/${postId}/`;
};

export const getCommentUrl = (
  communityId: string,
  postId: string,
  commentId: string
): string => {
  return `/communities/${communityId}/post/${postId}/?comment=${commentId}`;
}; // This works for replies as well

export const pushRouteWithSortBy = (
  router: NextRouter,
  sortBy: string
): void => {
  const query = { ...router.query, sortBy, page: 1 };
  const path = router.asPath.split('?')[0];
  const asPath = `${path}?${qs.stringify({ sortBy })}`;
  router.push(
    {
      pathname: router.pathname,
      query,
    },
    asPath
  );
};

export const setQueryParam = (
  router: NextRouter,
  key: string,
  value: string
): void => {
  const query = { ...router.query, [key]: value };
  const urlQuery = { ...query };

  delete urlQuery.communityId; // Just so ?communityId=... isn't appended to the browser

  const path = router.asPath.split('?')[0];
  const asPath = `${path}?${qs.stringify(urlQuery)}`;
  router.push(
    {
      pathname: router.pathname,
      query,
    },
    asPath
  );
};

export const goToHome = (
  router: NextRouter,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.home,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/`
  );
};

export const goToSearch = (
  router: NextRouter,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.search,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/search/`
  );
};

export const goToFollowing = (
  router: NextRouter,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.following,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/following/`
  );
};

export const goToMembers = (
  router: NextRouter,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.members,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/members/`
  );
};

export const goToNewSpace = (
  router: NextRouter,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.spaces.new,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/spaces/new/`
  );
};

export const goToSpaces = (
  router: NextRouter,
  spaceId: string,
  communityId: string,
  shouldScrollTop = false
): Promise<boolean | void> => {
  return router
    .push(
      {
        pathname: pathnames.spaces.space,
        query: {
          communityId,
          spaceId,
        },
      },
      `/communities/${communityId}/spaces/${spaceId}/`
    )
    .then(() => {
      if (shouldScrollTop) window.scrollTo(0, 0);
    });
};

export const goToEditSpace = (
  router: NextRouter,
  spaceId: string,
  communityId: string,
  query?: { [key: string]: string | boolean }
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.spaces.edit,
      query: {
        ...query,
        communityId,
        spaceId,
      },
    },
    `/communities/${communityId}/spaces/${spaceId}/edit/`
  );
};

export const goToPostPage = (
  router: NextRouter,
  communityId: string,
  postId: string,
  shouldScrollTop?: boolean,
  canUseBrowserBack?: boolean
): Promise<boolean | void> => {
  return router
    .push(
      {
        pathname: '/[communityId]/post/[postId]',
        query: {
          canUseBrowserBack,
          communityId,
          postId,
        },
      },
      getPostUrl(communityId, postId)
    )
    .then(() => {
      if (shouldScrollTop) window.scrollTo(0, 0);
    });
};

export const goToCommentPage = (
  router: NextRouter,
  communityId: string,
  postId: string,
  commentId: string
): Promise<boolean | void> => {
  // Adding the comment query param causes a full page refresh so we need to pass this via query
  // This also prevents the need for shouldScrollTop
  return router.push(
    {
      pathname: '/[communityId]/post/[postId]/?comment=[commentId]',
      query: {
        communityId,
        postId,
        commentId,
      },
    },
    `${getCommentUrl(communityId, postId, commentId)}&canUseBrowserBack=true`
  );
};

export const goToNewEvent = (
  router: NextRouter,
  communityId: string,
  query?: { stringifiedEvent: string; duplicatedEventId: string }
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.events.new,
      query: {
        communityId,
        ...query,
      },
    },
    `/communities/${communityId}/events/new/`
  );
};

export const goToEvents = (
  router: NextRouter,
  communityId: string
): Promise<boolean | void> => {
  return router.push(
    {
      pathname: pathnames.events.events,
      query: {
        communityId,
      },
    },
    `/communities/${communityId}/events`
  );
};

export const goToEvent = (
  router: NextRouter,
  eventId: string,
  communityId: string
): Promise<boolean | void> => {
  return router.push(
    {
      pathname: pathnames.events.event,
      query: {
        communityId,
        eventId,
        canUseBrowserBack: true,
      },
    },
    `/communities/${communityId}/events/${eventId}/`
  );
};

export const goToEditEvent = (
  router: NextRouter,
  eventId: string,
  communityId: string
): Promise<boolean> => {
  return router.push(
    {
      pathname: pathnames.events.edit,
      query: {
        communityId,
        eventId,
      },
    },
    `/communities/${communityId}/events/${eventId}/edit/`
  );
};

// Returns a boolean if we can tell by looking at browser info
// Returns null if uncertain
export const wasPageRefreshed = (): boolean | null => {
  const performance = window?.performance;

  if (performance) {
    // Deprecated approach but still works in most browsers
    if (
      performance?.navigation?.type != null &&
      performance.navigation?.type === performance.navigation?.TYPE_RELOAD
    ) {
      return true;
    }

    return window.performance
      .getEntriesByType('navigation')
      .map((nav) => {
        // @ts-ignore ... apparently type does exist
        return nav.type;
      })
      .includes('reload');
  }

  return null;
};

export const goToPostListPage = (
  router: NextRouter,
  communityId: string
) => (): void => {
  // If this post page has been navigated to by clicking a post on the listing page
  // then we can safely use the browser back button, which will maintain the scroll
  // postion of the post list. Otherwise, we want to manually navigate to the listing page.
  if (router.query.canUseBrowserBack) {
    router.back();
  } else {
    goToHome(router, communityId);
  }
};
