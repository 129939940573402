import { gql } from '@apollo/client';

export const SPACE_FRAGMENT = gql`
  fragment SpaceFields on CommunitySpace {
    id
    name
    description
    iconName
    position
    imageUrl
    imageAltText
    s3UploadPath
    isExclusive
    posts {
      totalCount
      edges {
        node {
          id
        }
      }
    }
    communityProductRelation @include(if: $isCoursesToCommunitiesEnabled) {
      id
      product {
        id
      }
    }
  }
`;

export const SPACE_QUERY = gql`
  query CommunitySpace(
    $communityId: ID!
    $spaceId: ID!
    $isCoursesToCommunitiesEnabled: Boolean!
  ) {
    community(id: $communityId) {
      id
      communitySpace(id: $spaceId) {
        ...SpaceFields
      }
    }
  }
  ${SPACE_FRAGMENT}
`;
