import React, { ReactNode, RefObject, useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';

import { useCommunityContext } from 'components/CommunityContext';
import TopBar from 'components/TopBar';
import MobileNav from 'components/MobileNav';
import ProfilePanel from 'components/Profile/ProfilePanel';
import { eventNames } from 'constants/eventNames';
import EventPanel from 'components/Events/components/EventPanel/EventPanel';
import useEventPanel from 'hooks/Events/useEventPanel';
import { setGlobalCSSVariables } from 'helpers/styleHelpers';
import ModeratorPanel from 'components/ModeratorPanel';

interface PubSubInfo {
  userId: string;
  lastElementRef: RefObject<HTMLElement>;
}

const SiteLayout: React.FC = ({ children }) => {
  const [isProfilePanelOpen, setIsProfilePanelOpen] = useState<boolean>(false);
  const [isModeratorPanelOpen, setIsModeratorPanelOpen] = useState<boolean>(
    false
  );
  const [lastFocusedElementRef, setLastFocusedElementRef] = useState<
    RefObject<HTMLElement>
  >();
  const [selectedUserId, setSelectedUserId] = useState<string>('');

  const { community, site } = useCommunityContext();
  const {
    eventId,
    handleCloseEventPanel,
    isOpen: isEventPanelOpen,
  } = useEventPanel(() => setIsProfilePanelOpen(false));
  const { t } = useTranslation();

  const router = useRouter();
  const isPostPage = router.pathname === '/[communityId]/post/[postId]';
  const isEventPage = router.pathname === '/[communityId]/events/[eventId]';
  const isSearchPage = router.pathname === '/[communityId]/search';

  useEffect(() => {
    setGlobalCSSVariables(community.primaryColor);
  }, [community]);

  useEffect(() => {
    const profileToken = PubSub.subscribe(
      eventNames.OPEN_PROFILE_PANEL,
      (msg: string, info: PubSubInfo) => {
        handleOpenProfilePanel(info);
      }
    );

    const moderatorToken = PubSub.subscribe(
      eventNames.OPEN_MODERATOR_PANEL,
      (msg: string, info: PubSubInfo) => {
        handleOpenModeratorPanel(info);
      }
    );

    return () => {
      PubSub.unsubscribe(profileToken);
      PubSub.unsubscribe(moderatorToken);
    };
  }, []);

  const handleCloseProfilePanel = () => {
    lastFocusedElementRef?.current?.focus();
    setSelectedUserId('');
    setIsProfilePanelOpen(false);
  };

  const handleOpenProfilePanel = (info: PubSubInfo) => {
    handleCloseEventPanel();
    setLastFocusedElementRef(info.lastElementRef);
    setSelectedUserId(info.userId);
    setIsProfilePanelOpen(true);
  };

  const handleOpenModeratorPanel = (info: PubSubInfo) => {
    setLastFocusedElementRef(info.lastElementRef);
    setIsModeratorPanelOpen(true);
  };

  const handleCloseModeratorPanel = () => {
    lastFocusedElementRef?.current?.focus();
    setIsModeratorPanelOpen(false);
  };

  return (
    <>
      <Head>
        <title>{community.name}</title>
        <meta content={community.name} name="description" />
        <link href={site.favicon} rel="shortcut icon" type="image/x-icon" />
      </Head>
      <TopBar
        isDashboardLinkVisible={!isPostPage && !isEventPage && !isSearchPage}
        t={t}
      />
      <MobileNav community={community} isPostView={isPostPage} />
      {children}
      <ProfilePanel
        isOpen={isProfilePanelOpen}
        userId={selectedUserId}
        onClose={handleCloseProfilePanel}
      />
      <EventPanel
        eventId={eventId}
        isOpen={isEventPanelOpen}
        onClose={handleCloseEventPanel}
      />
      <ModeratorPanel
        isOpen={isModeratorPanelOpen}
        onClose={handleCloseModeratorPanel}
      />
    </>
  );
};

export const getLayout = (page: ReactNode): ReactNode => (
  <SiteLayout>{page}</SiteLayout>
);

export default SiteLayout;
