import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Icon } from '@thinkific/toga-react';
import { H2, P } from 'koba/components/Typography';

import styles from './ProfilePostEmptyState.module.scss';

const cx = classNames.bind(styles);

interface ProfilePostEmptyStateProps {
  userName: string;
}

const ProfilePostEmptyState: React.FC<ProfilePostEmptyStateProps> = ({
  userName,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('profile-empty-state')}>
      <Icon
        className={cx('profile-empty-state__icon')}
        height={32}
        name="content-discussions"
        width={32}
      />
      <H2
        className={cx('profile-empty-state__title')}
        data-qa={cx('profile-empty-state__title')}
      >
        {t('No activity to show')}
      </H2>
      <P
        className={cx('profile-empty-state__subtitle')}
        data-qa="profile-empty-state__subtitle"
      >
        {t(
          'components-profile-profile_empty_state_no_recent_posts',
          '{{userName}} hasn’t posted in the last while.',
          {
            userName,
          }
        )}{' '}
        {t(
          'components-profile-profile_empty_state_suggestion',
          'Invite them to a conversation by mentioning their name.'
        )}
      </P>
    </div>
  );
};

export default ProfilePostEmptyState;
