import { gql } from '@apollo/client';
import {
  LIVE_EVENTS_EDGES_FRAGMENT,
  LIVE_EVENTS_INTERESTED_USERS_EDGES_FRAGEMENT,
  LIVE_EVENT_FRAGMENT,
} from './fragments';

export const LIVE_EVENTS_LIST_QUERY = gql`
  query LiveEvents(
    $eventStatus: EventStatusTypes
    $eventSourceId: ID!
    $numberOfEvents: Int
    $startDateTime: DateTime
    $endDateTime: DateTime
    $sortDirection: OrderDirection = ASC
  ) {
    site {
      id
      liveEvents(
        eventSourceType: Community
        eventSourceId: $eventSourceId
        first: $numberOfEvents
        orderBy: { field: start_time, direction: $sortDirection }
        filter: {
          eventStatus: $eventStatus
          startTime: $startDateTime
          endTime: $endDateTime
        }
      ) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          ...LiveEventsEdgesFields
        }
      }
    }
  }
  ${LIVE_EVENTS_EDGES_FRAGMENT}
`;

export const LIVE_EVENT_INTERESTED_USERS_FRAGMENT = gql`
  fragment EventInterestedUsersFields on LiveEvent {
    isInterested
    interestedUsers(first: $numberOfUsers, after: $endCursor) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        ...InterestedUsersEdgesFields
      }
    }
  }
  ${LIVE_EVENTS_INTERESTED_USERS_EDGES_FRAGEMENT}
`;

export const LIVE_EVENT_QUERY = gql`
  query LiveEvent($eventId: ID!, $numberOfUsers: Int = 5, $endCursor: String) {
    site {
      id
      liveEvent(id: $eventId) {
        ...EventFields
        ...EventInterestedUsersFields
      }
    }
  }
  ${LIVE_EVENT_FRAGMENT}
  ${LIVE_EVENT_INTERESTED_USERS_FRAGMENT}
`;

export const LIVE_EVENTS_COUNT_QUERY = gql`
  query LiveEventsCount($eventSourceId: ID!) {
    site {
      id
      liveEvents(eventSourceType: Community, eventSourceId: $eventSourceId) {
        totalCount
      }
    }
  }
`;
