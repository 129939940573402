import React from 'react';
import classNames from 'classnames/bind';
import { Surface, Icon } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';

import { eventNames } from 'constants/eventNames';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { useCommunityContext } from 'components/CommunityContext';
import styles from './CourseCardPlaceholder.module.scss';

const cx = classNames.bind(styles);

const CourseCardPlaceholder: React.FC = () => {
  const { t } = useTranslation();
  const { trackEvent } = useCommunityContext();

  const handleClick = () => {
    PubSub.publish(eventNames.OPEN_SELECT_COURSE_MODAL);
    trackEvent(TrackingEvent.CLICK_ADD_COURSE_TO_SPACE, {
      [TrackingProperty.COMPONENT]: 'CourseCardPlaceholder',
    });
  };

  return (
    <div
      aria-label={t('Add a course')}
      data-qa="course-card__placeholder"
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick();
        }
      }}
    >
      <Surface className={cx('course-card__add')}>
        <div className={cx('course-card__add__icon')}>
          <Icon height={48} name="plus" width={48} />
        </div>
        <p className={cx('course-card__add__title')}>{t('Link a course')}</p>
        <span className={cx('course-card__add__subtitle')}>
          {t(
            'components-courseCardPlaceholder-course_card_placeholder_description',
            'Select a course that students in your community have access to, creating a more holistic, dynamic learning experience.'
          )}
        </span>
      </Surface>
    </div>
  );
};

export default CourseCardPlaceholder;
