export const MAX_POST_TITLE_LENGTH = 124;
export const POSTS_PER_PAGE = 10;
export const POSTS_PER_PAGE_ON_HOME = 3;
export const COURSES_ON_PROFILE_VIEW = 3;
export const POSTS_ON_PROFILE_VIEW = 5;
export const TRENDING_POSTS_MAX = 5;
export const NUMBER_OF_REACTIONS = 7;
export const NUMBER_OF_EVENTS_ON_HOME = 2;
export const NUMBER_OF_INTERESTED_USERS_ON_EVENT = 15;
export const COMMENTS_PER_PAGE = 10;
export const NESTED_REPLIES_PER_PAGE = 3;
export const FEED_COMMENT_COUNT = 2;
export const FEED_REPLY_COUNT = 2;
export const DEFAULT_PAGE_SIZE = 10;
export const SITE_ADMIN = 'Site admin';
export const VIDEO_ATTACHMENT = 'video';
export const IMAGE_ATTACHMENT = 'image';
export const FILE_ATTACHMENT = 'file';
export const MAX_FILE_SIZE_MB = 100;

export const ACCEPTED_IMAGE_TYPES =
  'image/png,image/jpeg,image/gif,image/tiff,image/bmp';

export const ACCEPTED_FILE_TYPES = [
  'audio/aac',
  'application/x-abiword',
  'image/avif',
  'video/x-msvideo',
  'application/vnd.amazon.ebook',
  'image/bmp',
  'application/x-cdf',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/epub+zip',
  'image/gif',
  'image/vnd.microsoft.icon',
  'text/calendar',
  'image/jpeg',
  'audio/midi audio/x-midi',
  'audio/mpeg',
  'video/mp4',
  'video/mpeg',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'audio/ogg',
  'video/ogg',
  'application/ogg',
  'audio/opus',
  'font/otf',
  'image/png',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'image/tiff',
  'video/mp2t',
  'font/ttf',
  'text/plain',
  'audio/wav',
  'audio/webm',
  'video/webm',
  'image/webp',
  'font/woff',
  'font/woff2',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'video/3gpp',
  'audio/3gpp',
  'video/3gpp2',
  'audio/3gpp2',
  'video/quicktime',
].join(',');

export const EVENT_IMAGE_PLACEHOLDER =
  'https://cdn.thinkific.com/93/20220421/9868e43f4247dbf1d086360508bd03e5.svg';

export const SPACE_IMAGE_PLACEHOLDER =
  'https://cdn.thinkific.com/90/20220929/d3ea6e7552f6a1f8aff61af579236d75.png';

export const HOME_FEED_LATEST_POSTS_MODERATOR =
  'https://cdn.thinkific.com/90/20220818/94f6972461742c3c8d334a8bc892ae77.jpg';

export const HOME_FEED_LATEST_POSTS_STUDENT =
  'https://cdn.thinkific.com/90/20220818/8ca39b4a34589399fe7bb08390bf88da.jpg';

export const WELCOME_TO_SPACES =
  'https://cdn.thinkific.com/88/20220913/94833666faa126890ce3ea133d69734f.png';

export const SPACES_EMPTY_STATE_MODERATOR =
  'https://cdn.thinkific.com/88/20220915/ab8c7adb71ef4a833802c1f60247569d.jpg';

export const SPACES_EMPTY_STATE_STUDENT =
  'https://cdn.thinkific.com/88/20220915/5ce16b721823f2d43c989dcdb6426b93.jpg';

export const FOLLOWING_EMPTY_STATE_ENGLISH =
  'https://cdn.thinkific.com/90/20221012/a8e520e6342120c9a851543f97af4abf.png';
export const FOLLOWING_EMPTY_STATE_SPANISH =
  'https://cdn.thinkific.com/90/20221101/7544d2bed8d2efd6b10db69d89888af5.png';
export const FOLLOWING_EMPTY_STATE_FRENCH =
  'https://cdn.thinkific.com/90/20221101/2cde57a4a7e53f8a835df45bd11bd43f.png';

export const COMMENT_FORM_ID = 'new-comment';

export const AWS_S3_URL = 's3.amazonaws.com';

// User roles
export const USER_ROLES = {
  SITE_OWNER_SITE_ADMIN: 'Site owner, Site admin',
  SITE_ADMIN: 'Site admin',
  PARTNER: 'Partner',
};

// A "postOrderField" is passed into the POST_LIST_QUERY as a field to order posts by
export const POST_ORDER_FIELDS = {
  REPLY_COUNT: 'REPLY_COUNT',
  PINNED_AT: 'PINNED_AT',
  LAST_INTERACTION_AT: 'LAST_INTERACTION_AT',
  CREATED_AT: 'CREATED_AT',
};

// A "sortBy" param is included in the url to sort posts by
export const SORT_BY_PARAMS = {
  RECENT: 'recent',
  MOST_COMMENTS: 'most-comments',
  RECENT_COMMENTS: 'recent-comments',
};

// This constant maps the "sortBy" param with it's corresponding "postOrderField"
export const SORT_BY_TO_POST_ORDER_FIELDS = {
  [SORT_BY_PARAMS.RECENT]: POST_ORDER_FIELDS.PINNED_AT,
  [SORT_BY_PARAMS.MOST_COMMENTS]: POST_ORDER_FIELDS.REPLY_COUNT,
  [SORT_BY_PARAMS.RECENT_COMMENTS]: POST_ORDER_FIELDS.LAST_INTERACTION_AT,
};

/** Communities breakpoint */
export const SCREEN_PHONE = 481;
export const SCREEN_LG = 1024;

/** from toga lib */
export const BREAKPOINT_SM = 664;
export const BREAKPOINT_MD = 952;
export const BREAKPOINT_LG = 1265;
export const BREAKPOINT_XL = 1440;

export const FILTER_FOLLOWING = 'following';

export const EVENT_POST_TYPES = {
  COMMENT: 'comment',
  POST: 'post',
  REPLY: 'reply',
};

export const EVENT_USER_ROLES = {
  SITE_ADMIN: 'site admin',
  STUDENT: 'student',
};

export const EVENT_NOTIFICATION_SETTINGS = {
  ALL_COMMENTS: 'all comments',
  OFF: 'off',
};

/** for shared resources */
export const POWERED_BY_THINKIFIC =
  'https://cdn.thinkific.com/66/20191001/9f313492f05380a8361fe9ffb644026f.svg';
export const POWERED_BY_THINKIFIC_ALT = 'Powered by Thinkific';
export const EVENT_ADOPTION_IMAGE =
  'https://cdn.thinkific.com/90/20220622/2f6c70fcac06237a3918b92bf0dd4561.png';
export const TRENDING_POSTS_IMAGE =
  'https://cdn.thinkific.com/93/20220816/3af65c258d55e6a20cc947de7ecbb1d1.png';
export const EVENT_LEARN_MORE_LINK =
  'https://support.thinkific.com/hc/en-us/articles/6746444680087';
export const CREATE_POST_SECTION =
  'https://support.thinkific.com/hc/en-us/articles/360034240434-Manage-Your-Community#01GEHN3AH92HMY9SAPA010D7TP';
export const FILE_TYPES_ARTICLE =
  'https://support.thinkific.com/hc/en-us/articles/10380215164951';
export const EVENT_REMINDER_EMAIL_LEARN_MORE_LINK =
  'https://support.thinkific.com/hc/en-us/articles/6746444680087';
export const EVENT_LEARN_MORE_PREVIEW_EMAIL_LINK =
  'https://support.thinkific.com/hc/en-us/articles/6746444680087-Communities-Live-Events';
export const EVENT_LEARN_MORE_PREVIEW_EMAIL_SHARE_LINK =
  'https://support.thinkific.com/hc/en-us/articles/6746444680087-Communities-Live-Events#notifyandshare';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

export const SUCCESS_TOAST = {
  DELETE_POST: 'Successfully deleted post.',
  DELETE_COMMENT: 'Successfully deleted comment.',
  DELETE_REPLY: 'Successfully deleted reply.',
  EDIT_POST: 'Successfully edited post.',
  EDIT_COMMENT: 'Successfully edited comment.',
  EDIT_REPLY: 'Successfully edited reply.',
  CREATE_POST: 'Successfully created post.',
  CREATE_SPACE: 'Successfully created Space.',
  UPDATE_SPACE: 'Successfully updated Space.',
  NOTIFICATION_UPDATE: 'Successfully updated your notification settings.',
  ERROR_MSG: 'Something went wrong. Please try again.',
};

export const REACTION_TYPES = {
  LIKE_POST: 'like',
};

export const REACTION_ICONS = {
  LIKE_POST_TRUE: 'heart-fill',
  LIKE_POST_FALSE: 'heart-outline',
};

// format => react-toga: rails-api
export const SPACE_ICONS = {
  'content-discussions': 'discussions',
  'content-download': 'download',
  'content-audio': 'audio',
  'content-live': 'live',
  'circle-outline-price': 'price',
  envelope: 'envelope',
  'content-video': 'video',
  'content-pdf': 'pdf',
  trophy: 'trophy',
  image: 'image',
  certificate: 'certificate',
  shield: 'shield',
  'content-presentation': 'presentation',
  location: 'location',
  key: 'key',
  flag: 'flag',
};

export const DEFAULT_SPACE_ICON = SPACE_ICONS['content-discussions'];

// will be used when toga avatar component is updated with background-color prop
export const USER_AVATAR_COLORS = [
  '#36394d', // darkblue
  '#57608c', // mediumblue
  '#3d47a3', // royalblue
  '#00699e', // lightblue
  '#006f63', // teal
  '#216e2f', // green
  '#100D59', // indigo
  '#af4d1e', // orange
  '#a62626', // red
  '#810073', // purple
];

export const USER_ERRORS_API_CODES = {
  space_invalid: 'SPACE_INVALID',
  space_name_error: 'SPACE_NAME_ERROR',
  not_allowed_on_plan: 'NOT_ALLOWED_ON_PLAN',
  event_time_error: 'EVENT_TIME_ERROR',
  event_attendance_already_attending: 'ALREADY_ATTENDING',
  event_attendance_not_attending: 'NOT_ATTENDING',
};
export const MENTIONS_PLACEHOLDER_POST_TEXT =
  'Type @ to mention another member in your post';
export const MENTIONS_PLACEHOLDER_COMMENT_TEXT =
  'Type @ to mention another member in your comment';

export const MEMBERS_MAX_LIMIT = 25;
