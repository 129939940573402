import React, { SyntheticEvent } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { Icon } from '@thinkific/toga-react';

import { useCommunityContext } from 'components/CommunityContext';
import { handleEditEvent } from 'helpers/eventHelpers';
import Button from 'koba/components/Button';

import styles from './EditEventButton.module.scss';

const cx = classNames.bind(styles);

interface EditEventButtonProps {
  eventId: string;
  parentComponent: string;
  callback?: () => void;
  hasIcon?: boolean;
  title: string;
  className?: string;
}

const EditEventButton: React.FC<EditEventButtonProps> = ({
  eventId,
  parentComponent,
  callback,
  hasIcon = false,
  title,
  className,
}) => {
  const { community, trackEvent } = useCommunityContext();
  const router = useRouter();

  const handleEditClick = (e: SyntheticEvent) => {
    handleEditEvent(
      e,
      router,
      trackEvent,
      community.id,
      eventId,
      parentComponent
    );
    callback?.();
  };

  return (
    <Button
      appearance="ghost"
      className={cx('edit-event-button', className)}
      dataQA="event-panel__edit-button"
      href={`/communities/${community?.id}/events/${eventId}/edit/`}
      onClick={handleEditClick}
    >
      {hasIcon && <Icon name="pencil" />}
      {title}
    </Button>
  );
};

export default EditEventButton;
