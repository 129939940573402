import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';
import styles from './ProfilePostSkeleton.module.scss';

const cx = classNames.bind(styles);

export const ProfilePostSkeleton: React.FC = () => (
  <div className={cx('profile-post-skeleton')}>
    <div className={cx('profile-post-skeleton__avatar')} />
    <div className={cx('profile-post-skeleton__body')}>
      <SkeletonLine width="80px" />
      <SkeletonLine width="160px" />
    </div>
  </div>
);

export const ProfilePostSkeletonContainer: React.FC = () => {
  return (
    <div
      className={cx('profile-post-skeleton__container')}
      data-qa="profile-post-skeleton__container"
    >
      <ProfilePostSkeleton />
      <ProfilePostSkeleton />
      <ProfilePostSkeleton />
    </div>
  );
};

export default {
  ProfilePostSkeleton,
  ProfilePostSkeletonContainer,
};
