import React from 'react';
import classNames from 'classnames/bind';
import styles from '../Dropdown.module.scss';

const cx = classNames.bind(styles); // eslint-disable-line

interface ItemProps {
  className?: string;
  multipleActions?: boolean;
  title?: string;
  onClick?: () => void;
}

const Item: React.FC<ItemProps> = ({
  children,
  multipleActions,
  className: propClasses = '',
  ...attrs
}) => {
  const className = cx('dropdown__list-item', {
    'dropdown__list-item--actions': multipleActions,
  });

  return (
    <li className={`${className} ${propClasses}`} {...attrs}>
      {children}
    </li>
  );
};

export default Item;
