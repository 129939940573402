import { DateTime, DateTimeFormatOptions } from 'luxon';
import { TFunction } from 'next-i18next';

export const ONE_MINUTE = 60 * 1000;
export const DEFAULT_EVENT_STEP_MINUTES = 30;
export const EVENT_DATE_ISO_OPTIONS = {
  includeOffset: false,
  suppressMilliseconds: true,
};
export const TIMEZONE_FORMAT = 'hh:mm a, (ZZZZ - ZZZZZ)';

const fromISO = (date: string, zone?: string) => {
  // It's a bit silly that empty string for zone just returns an error
  // This helper will just ensure that we default to local time if timezone is empty string
  return DateTime.fromISO(date, { zone: zone || undefined });
};

const relativeFormatDateTime = (
  date: string,
  locale: string | undefined
): string | null => DateTime.fromISO(date, { locale }).toRelative();

const createdLessThanAMinuteAgo = (date: string): boolean => {
  return new Date().valueOf() - DateTime.fromISO(date).valueOf() < ONE_MINUTE;
};

export const relativeFormattedDateTime = (
  time: string,
  locale: string | undefined,
  t: TFunction
): string | null =>
  createdLessThanAMinuteAgo(time)
    ? t('a few seconds ago')
    : relativeFormatDateTime(time, locale);

export const localeEventsDate = (
  date: string,
  locale?: string | null,
  zone?: string,
  options?: DateTimeFormatOptions
): string => {
  const dateTime = fromISO(date, zone);
  if (dateTime.isValid) {
    // Avoid showing year if its the current one
    const isCurrentYear = DateTime.now().year === dateTime.year; // ToDo: Add some tests for this line around Dec 31st

    const opts = {
      ...(options || {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      }),
    };

    if (!isCurrentYear) opts.year = 'numeric';

    return dateTime.setLocale(locale || '').toLocaleString(opts);
  }
  return '';
};

export const isEventLive = (startTime: string, endTime: string): boolean => {
  const start = DateTime.fromISO(startTime);
  const end = DateTime.fromISO(endTime);
  const now = DateTime.now();

  return now >= start && now <= end;
};

export const isEventStartingSoon = (
  startTime: string,
  rangeInMins = 15
): boolean => {
  const start = DateTime.fromISO(startTime);
  const now = DateTime.now();
  const diff = start.diff(now, ['minutes']).toObject();

  if (!diff.minutes) return false;

  return diff.minutes <= rangeInMins && diff.minutes > 0;
};

export const checkIfPastDateTime = (
  date: string,
  timezone: string
): boolean => {
  if (!timezone) return false;
  if (DateTime.now() > DateTime.fromISO(date, { zone: timezone })) return true;

  return false;
};

export const getRoundedDateTime = (
  date: DateTime,
  step = DEFAULT_EVENT_STEP_MINUTES
): DateTime => {
  const remainder = step - (date.minute % step);
  const roundedStartDate = date.plus({ minutes: remainder }).startOf('minute');

  return roundedStartDate;
};

export const getRoundedISOTime = (
  date: string,
  step = DEFAULT_EVENT_STEP_MINUTES
): string => {
  const dateTime = DateTime.fromISO(date, { setZone: true });
  const roundedStartDate = getRoundedDateTime(dateTime, step);

  return roundedStartDate.toISO(EVENT_DATE_ISO_OPTIONS);
};

export const formatTimeOptionText = (
  currentDate: DateTime,
  t: TFunction,
  startDate?: DateTime,
  withDuration?: boolean
): string => {
  const text = currentDate.toLocaleString(DateTime.TIME_SIMPLE);
  if (withDuration && startDate) {
    const diff = currentDate.diff(startDate).as('hours');
    const rounded = Math.round(diff * 10) / 10;
    return `${text} (${rounded} ${t(
      'helpers-date-hours_abbreviation',
      'hrs'
    )})`;
  }
  return text;
};

export const getUTCDateTime = (date: string, zone: string): string => {
  return DateTime.fromISO(date, { zone }).toUTC().toISO(EVENT_DATE_ISO_OPTIONS);
};

export const getISOFromUTC = (date: string, zone: string): string => {
  return DateTime.fromISO(date, { zone }).toISO(EVENT_DATE_ISO_OPTIONS);
};
