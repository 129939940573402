export const features = {
  STUDENT_EVENT_TRACKING: 'feature.communities-student-event-tracking',
  COURSES_TO_COMMUNITIES: 'feature.courses-to-communities-link',
  NOTIFICATION_PANE: 'feature.notification-pane',
  COMMUNITIES_AS_A_PRODUCT: 'feature.communities-as-a-product',
  DEV_COMMUNITIES_DEBUGGING: 'dev-communities-debugging',
  COMMUNITIES_EVENTS_RSVP: 'feature.communities-events-rsvp',
  COMMUNITIES_EVENT_EMAILS: 'feature.live-event-emails',
  EXCLUSIVE_SPACES: 'feature.community-exclusive-spaces',
  COMMENTS_IN_FEED: 'feature.communities-comments-in-feed',
  COMMUNITIES_MODERATOR_ROLE: 'feature.communities-moderator-role',
  LIVE_EVENT_REMINDERS: 'events.live-event-reminders',
  COMMUNITIES_SEARCH: 'feature.communities-post-search',
};
