import React from 'react';
import classNames from 'classnames/bind';
import { Button, Icon } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { goToNewSpace } from 'utils/routerHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { isAdmin } from 'utils/userHelpers';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import CommonActions from './components/CommonActions';
import SpacesList from './components/SpacesList';

import styles from './CommunityActionsList.module.scss';

const cx = classNames.bind(styles);

interface CommunityActionsListProps {
  handleMobileNavClick?: () => void;
}

const CommunityActionsList: React.FC<CommunityActionsListProps> = ({
  handleMobileNavClick,
}) => {
  const { t } = useTranslation();
  const { community, trackEvent, currentUser } = useCommunityContext();
  const router = useRouter();

  const handleCreateSpaceClick = (e: Event) => {
    e.preventDefault();
    if (handleMobileNavClick) handleMobileNavClick();

    trackEvent(TrackingEvent.GO_TO_NEW_SPACE, {
      [TrackingProperty.COMPONENT]: 'CommunityActionsList',
    });
    goToNewSpace(router, community.id);
  };

  return (
    <div className={cx('community-actions-list__wrapper')}>
      <aside className={cx('community-actions-list')}>
        <CommonActions handleMobileNavClick={handleMobileNavClick} />
        <SpacesList handleMobileNavClick={handleMobileNavClick} />
        {isAdmin(currentUser) && (
          <Button
            appearance="knockout"
            className={cx('community-actions-list__create-space')}
            data-qa="community-actions-list__create-space"
            href={`/communities/${community.id}/spaces/new`}
            type="button"
            onClick={handleCreateSpaceClick}
          >
            <Icon name="plus" aria-hidden />
            {t('common-CREATE_SPACE', 'CREATE SPACE')}
          </Button>
        )}
      </aside>
    </div>
  );
};

export default CommunityActionsList;
