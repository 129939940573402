import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './SkeletonPost.module.scss';

const cx = classNames.bind(styles);

export const SkeletonLine: FC<{ width?: string; height?: string }> = ({
  width = '100%',
  height = '6px',
}) => <div className={cx('skeleton-line')} style={{ width, height }} />;

SkeletonLine.displayName = 'SkeletonLine';

export const SkeletonPost: FC = () => (
  <div className={cx('skeleton-post')}>
    <div className={cx('skeleton-post__details')}>
      <div className={cx('skeleton-post__avatar')} />
      <div className={cx('skeleton-comment__name')}>
        <SkeletonLine width="80px" />
        <SkeletonLine width="160px" />
      </div>
    </div>
    <div className={cx('skeleton-post__body')}>
      <SkeletonLine width="70%" />
      <SkeletonLine width="100%" />
      <SkeletonLine width="30%" />
    </div>
  </div>
);

SkeletonPost.displayName = 'SkeletonPost';

export const SkeletonPostContainer: FC = () => (
  <div
    className={cx('skeleton-post__container')}
    data-qa="skeleton-post__container"
  >
    <SkeletonPost />
    <SkeletonPost />
    <SkeletonPost />
  </div>
);

SkeletonPostContainer.displayName = 'SkeletonPostContainer';

export default {
  SkeletonLine,
  SkeletonPost,
  SkeletonPostContainer,
};
