import React, { Component, ErrorInfo } from 'react';
import { Notifier } from '@airbrake/browser';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, unknown> {
  private readonly environment: string;

  private airbrake: Notifier;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    const projectId = parseInt(process.env.AIRBRAKE_PROJECT_ID, 10);
    const projectKey = process.env.AIRBRAKE_PROJECT_KEY;
    this.environment = process.env.AIRBRAKE_PROJECT_ENV;

    this.airbrake = new Notifier({
      projectId,
      projectKey,
      environment: this.environment,
    });

    this.airbrake.addFilter((notice) => {
      if (this.environment === 'development') {
        return null;
      }
      return notice;
    });

    // This check may need to be updated when we upgrade our version of NextJS
    // https://github.com/vercel/next.js/issues/5354#issuecomment-520305040
    if (typeof window !== 'undefined') {
      window.airbrake = this.airbrake;
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.airbrake.notify({
      error,
      params: { info },
    });
  }

  render(): React.ReactNode {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
