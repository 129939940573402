import { useMutation } from '@apollo/client';
import { failureToastMessage, genericToastAlert } from 'utils/toastHelpers';

import { UserError } from 'types/errors';
import { GraphQLError } from 'graphql';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import {
  CREATE_ATTENDANCE_MUTATION,
  DELETE_ATTENDANCE_MUTATION,
} from 'schema/Event/mutations';
import {
  CreateAttendanceMutation,
  CreateAttendanceMutationVariables,
  DeleteAttendanceMutation,
  DeleteAttendanceMutationVariables,
} from '__generated__/graphql/legacy/graphql';
import { useCommunityContext } from 'components/CommunityContext';
import { useTranslation } from 'react-i18next';
import { USER_ERRORS_API_CODES } from 'utils/constants'; // get from generated?
import { rawId } from 'utils/rawId';

const useEventInterest = (
  id?: string
): {
  addInterest(): Promise<void>;
  removeInterest(): Promise<void>;
  loading: boolean;
} => {
  const { t } = useTranslation();
  const { trackEvent } = useCommunityContext();

  const [createAttendanceMutation, { loading: createLoading }] = useMutation<
    CreateAttendanceMutation,
    CreateAttendanceMutationVariables
  >(CREATE_ATTENDANCE_MUTATION, {
    onError: genericToastAlert,
    update: (cache, result) => {
      const updatedEvent = result?.data?.createAttendance?.liveEvent;
      if (updatedEvent) {
        cache.evict({
          id: cache.identify({
            id: updatedEvent.id,
            __typename: updatedEvent.__typename,
          }),
          fieldName: 'interestedUsers',
          // We could add args here to optimize this further ( args { first: 5 }) but that would make this code super fragile :(
        });
        cache.gc();
      }
    },
  });

  const [deleteAttendanceMutation, { loading: deleteLoading }] = useMutation<
    DeleteAttendanceMutation,
    DeleteAttendanceMutationVariables
  >(DELETE_ATTENDANCE_MUTATION, {
    onError: genericToastAlert,
    update: (cache, result) => {
      const updatedEvent = result?.data?.deleteAttendance?.liveEvent;
      if (updatedEvent) {
        cache.evict({
          id: cache.identify({
            id: updatedEvent.id,
            __typename: updatedEvent.__typename,
          }),
          fieldName: 'interestedUsers',
        });
        cache.gc();
      }
    },
  });

  const handleResponse = (
    trackingEvent,
    userErrors?: UserError[] | null,
    requestErrors?: readonly GraphQLError[]
  ) => {
    const alreadyAttendingFailure = userErrors?.find(
      (error) =>
        error.code === USER_ERRORS_API_CODES.event_attendance_already_attending
    );
    const notAttendingFailure = userErrors?.find(
      (error) =>
        error.code === USER_ERRORS_API_CODES.event_attendance_not_attending
    );

    if (!userErrors?.length && !requestErrors?.length) {
      trackEvent(trackingEvent, {
        [TrackingProperty.LIVE_EVENT_ID]: rawId(id || ''),
        [TrackingProperty.LIVE_EVENT_ID_ENCODED]: id,
      });
    } else if (alreadyAttendingFailure) {
      failureToastMessage(
        t(
          'pages-events-user_already_attending',
          'You are already attending that event.'
        )
      );
    } else if (notAttendingFailure) {
      failureToastMessage(
        t(
          'pages-events-user_not_attending',
          'You are not attending that event.'
        )
      );
    } else {
      genericToastAlert();
    }
  };

  const handleCreateAttendance = async () => {
    const { data, errors } = await createAttendanceMutation({
      variables: {
        input: {
          eventId: id || '',
        },
      },
      refetchQueries: ['LiveEvent'],
    });

    const userErrors = data?.createAttendance?.userErrors;

    handleResponse(TrackingEvent.CREATE_ATTENDANCE, userErrors, errors);
  };

  const handleRemoveAttendance = async () => {
    const { data, errors } = await deleteAttendanceMutation({
      variables: {
        input: {
          eventId: id || '',
        },
      },
      refetchQueries: ['LiveEvent'],
    });

    const userErrors = data?.deleteAttendance?.userErrors;
    handleResponse(TrackingEvent.DELETE_ATTENDANCE, userErrors, errors);
  };

  return {
    addInterest: handleCreateAttendance,
    removeInterest: handleRemoveAttendance,
    loading: createLoading || deleteLoading,
  };
};

export default useEventInterest;
