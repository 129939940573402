import React, { ReactNode, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@thinkific/toga-react';
import { useRouter } from 'next/router';

import useFocusTrap from 'hooks/useFocusTrap';
import useEscape from 'hooks/useEscape';
import { MenuTabSize } from 'types';
import CommunityStatusBadge from 'components/CommunityStatusBadge/CommunityStatusBadge';

import styles from './MenuTab.module.scss';

const cx = classNames.bind(styles);

interface MenuContentProps {
  buttonClassName?: string;
  children: ReactNode;
  iconName?: string;
  isOpen: boolean;
  hasOverlay?: boolean;
  hasHeader?: boolean;
  menuTitle?: string;
  slideTo?: string;
  size?: MenuTabSize;
  showCommunityStatus?: boolean;
  title?: string;
  hasOpenButton?: boolean;
  onClose: () => void;
  onOpen?: () => void;
  wrapperClassName?: string;
}

const MenuTab: React.FC<MenuContentProps> = ({
  buttonClassName,
  children,
  iconName,
  isOpen,
  hasOverlay,
  hasHeader = true,
  hasOpenButton,
  menuTitle,
  slideTo = 'right',
  size = 'medium',
  showCommunityStatus = false,
  title = '',
  onClose,
  onOpen,
  wrapperClassName,
}) => {
  const menuRef = useRef(null);
  const { t } = useTranslation();
  const router = useRouter();

  useFocusTrap(menuRef);
  useEscape(onClose);

  useEffect(() => {
    document.body.classList.toggle(cx('hide-scroll'), isOpen);
    return () => document.body.classList.remove(cx('hide-scroll'));
  }, [isOpen]);

  useEffect(() => {
    // If doesn't has overlay it should close when route changes
    if (!hasOverlay && isOpen) {
      onClose();
    }
  }, [router.asPath, hasOverlay]);

  return (
    <>
      {hasOpenButton && (
        <IconButton
          appearance="utility"
          aria-label={title}
          name={iconName}
          title={title}
          onClick={onOpen}
        />
      )}
      <div
        className={cx('menu-tab__container', size, {
          'is-menu-open': isOpen,
          'slide-to-left': slideTo === 'left',
          'no-header': !hasHeader,
        })}
        data-qa="menu-tab__container"
        ref={menuRef}
      >
        {hasHeader && (
          <div className={cx('menu-tab__header')} data-qa="menu-tab__header">
            <div>
              <span title={menuTitle}>{menuTitle}</span>
              {showCommunityStatus && <CommunityStatusBadge />}
            </div>
            <IconButton
              appearance="utility"
              aria-label={t('Close the menu')}
              className={cx('menu-tab__button', buttonClassName)}
              name="x-dismiss"
              title={t('Close the menu')}
              onClick={onClose}
            />
          </div>
        )}
        <div
          className={cx(
            'menu-tab__content',
            { 'is-menu-open': isOpen },
            wrapperClassName
          )}
        >
          {children}
        </div>
      </div>
      {hasOverlay && (
        <div
          aria-hidden="true"
          className={cx('menu-tab__overlay', {
            'is-menu-open': isOpen,
          })}
          data-qa="menu-tab__overlay"
          role="button"
          tabIndex={-1}
          onClick={onClose}
        />
      )}
    </>
  );
};

export default MenuTab;
