import React from 'react';
import classNames from 'classnames/bind';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Badge, IconButton } from '@thinkific/toga-react';

import { H2 } from 'koba/components/Typography';
import MenuTab from 'koba/components/MobileMenu/components/MenuTab';
import { useCommunityContext } from 'components/CommunityContext';
import {
  SCREEN_LG,
  USER_ROLES,
  EVENT_IMAGE_PLACEHOLDER,
} from 'utils/constants';
import { canAccess } from 'utils/userHelpers';
import { useWindowSize } from 'hooks/useWindowSize';
import { useRouter } from 'next/router';
import Button from 'koba/components/Button';

import { isEventLive, isEventStartingSoon } from 'helpers/dateHelpers';
import { a11yOnClick, A11YEvent } from 'helpers/interactions';
import TrackingEvent from 'constants/trackingEvents';
import { genericToastAlert } from 'utils/toastHelpers';
import { useQuery } from '@apollo/client';
import { LIVE_EVENT_QUERY } from 'schema/Event/queries';
import {
  LiveEventQuery,
  LiveEventQueryVariables,
} from '__generated__/graphql/legacy/graphql';
import { features } from 'constants/featureFlags';
import { handleJoinMeeting } from 'helpers/eventHelpers';
import { goToEvent } from 'utils/routerHelpers';
import { useSiteFeaturesContext } from 'components/SiteFeaturesContext';
import EventInterestedButton from './components/EventInterestedButton/EventInterestedButton';
import EventInterestedUsers from './components/EventInterestedUsers/EventInterestedUsers';
import EventContent from './components/EventContent/EventContent';

import styles from './EventPanel.module.scss';
import EditEventButton from './components/EditEventButton/EditEventButton';

const cx = classNames.bind(styles);
interface EventPanelProps {
  isOpen: boolean;
  onClose: () => void;
  eventId?: string | null;
}

const EventPanel: React.FC<EventPanelProps> = ({
  isOpen,
  onClose,
  eventId,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const {
    currentUser,
    community,
    trackEvent,
    featureFlags,
  } = useCommunityContext();
  const { siteFeatures } = useSiteFeaturesContext();
  const eventNotificationsEnabled =
    siteFeatures?.communityEventNotifications?.isEnabled;
  const rsvpEnabled = featureFlags[features.COMMUNITIES_EVENTS_RSVP];
  const { data: eventData, loading: liveEventLoading } = useQuery<
    LiveEventQuery,
    LiveEventQueryVariables
  >(LIVE_EVENT_QUERY, {
    variables: {
      eventId: eventId || '',
    },
    skip: !eventId,
    notifyOnNetworkStatusChange: true,
    onError: () => {
      genericToastAlert();
    },
  });

  const event = eventData?.site?.liveEvent;

  const { width } = useWindowSize();
  const isMobile = width < SCREEN_LG;
  const hasModeratorAccess = canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    currentUser
  );

  const { profile, locale } = currentUser;
  const userTimezone = profile?.timezone || undefined;

  const endTime = DateTime.fromISO(event?.endTime || '', {
    zone: userTimezone,
    locale,
  });

  const isPastEvent = DateTime.now() > endTime;
  const canJoinEvent =
    event?.meetingUrl &&
    (isEventLive(event?.startTime, event?.endTime) ||
      isEventStartingSoon(event?.startTime, 15));
  const showEventInterestedButton =
    !!event &&
    !hasModeratorAccess &&
    rsvpEnabled &&
    !isPastEvent &&
    !canJoinEvent &&
    eventNotificationsEnabled;
  const showInterestedUsers =
    rsvpEnabled && event && !isPastEvent && eventNotificationsEnabled;

  const closeButton = !isMobile && (
    <IconButton
      appearance="utility"
      className={cx('event-panel__close')}
      data-qa="event-panel__close"
      name="x-dismiss"
      title={t('components-events-eventPanel-close_panel', 'Close event panel')}
      onClick={onClose}
    />
  );

  const handleTitleClick = (e) => {
    e.preventDefault();

    goToEvent(router, event?.id || '', community.id);
  };

  return (
    <MenuTab
      hasHeader={isMobile}
      hasOverlay={isMobile}
      isOpen={isOpen}
      menuTitle={t('components-events-eventPanel-title', 'Event')}
      size={isMobile ? 'medium' : 'large'}
      slideTo="left"
      wrapperClassName={cx('event-panel')}
      onClose={onClose}
    >
      <aside
        aria-label=""
        className={cx('event-panel__wrapper')}
        data-qa="event-panel__wrapper"
        role="dialog"
      >
        <div className={cx('event-panel__header')}>
          <img
            alt={event?.imageAltText || ''}
            src={event?.imageUrl || EVENT_IMAGE_PLACEHOLDER}
          />
          {closeButton}
        </div>
        <div className={cx('event-panel__content')}>
          {!isMobile ? (
            <a
              className={cx('event-panel__title')}
              data-qa="event-panel__title"
              href={`/communities/${community?.id}/events/${event?.id}/`}
              {...a11yOnClick<HTMLAnchorElement>(handleTitleClick)}
            >
              {event?.title}
            </a>
          ) : (
            <H2 data-qa="event-panel__title">{event?.title}</H2>
          )}

          {showInterestedUsers && <EventInterestedUsers eventId={event.id} />}
          {isPastEvent && (
            <Badge type="secondary">
              {t('components-events-eventPanel-past_event', 'Past event')}
            </Badge>
          )}

          {event && <EventContent event={event} parentComponent="EventPanel" />}
        </div>
        <div
          className={cx('event-panel__footer')}
          data-qa="event-panel__footer"
        >
          {hasModeratorAccess && (
            <EditEventButton
              callback={onClose}
              eventId={event?.id || ''}
              parentComponent="EventPanel"
              title={t('common-EDIT', 'EDIT')}
            />
          )}

          {showEventInterestedButton && (
            <EventInterestedButton
              eventId={event?.id}
              isDisabled={liveEventLoading}
              isInterested={event?.isInterested || false}
            />
          )}

          {canJoinEvent && (
            <Button
              appearance="default"
              ariaLabel={t(
                'components-events-eventPanel-access_meeting',
                `Click to join the meeting. The meeting will be opened in a new window.`
              )}
              color={community.primaryColor}
              dataQA="event-panel__join-button"
              href={event?.meetingUrl || ''}
              isDisabled={isPastEvent}
              target="_blank"
              {...a11yOnClick<HTMLAnchorElement>(
                (e: A11YEvent<HTMLAnchorElement>) =>
                  handleJoinMeeting(
                    e,
                    TrackingEvent.CLICK_JOIN_LIVE_EVENT,
                    trackEvent,
                    event.id,
                    event.meetingUrl,
                    'EventPanel'
                  )
              )}
            >
              {t('common-JOIN', 'JOIN')}
            </Button>
          )}
        </div>
      </aside>
    </MenuTab>
  );
};

export default EventPanel;
