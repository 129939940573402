import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@thinkific/toga-react';
import PubSub from 'pubsub-js';
import classNames from 'classnames/bind';
import { rawId } from 'utils/rawId';
import { AvatarSize } from 'types';
import { USER_AVATAR_COLORS } from 'utils/constants';
import { eventNames } from 'constants/eventNames';
import { useCommunityContext } from 'components/CommunityContext';
import TrackingEvent from 'constants/trackingEvents';

import styles from './UserAvatar.module.scss';

const cx = classNames.bind(styles);

interface UserAvatarProps {
  alt?: string | null;
  avatarUrl?: string | null;
  className: string;
  username: string;
  userId: string;
  size: AvatarSize;
  srcSet?: string;
  shouldOpenProfile?: boolean;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({
  avatarUrl,
  className,
  username,
  userId,
  size,
  srcSet,
  shouldOpenProfile = false,
  alt,
}) => {
  const { trackEvent } = useCommunityContext();
  const userAvatarRef = useRef(null);
  const { t } = useTranslation();

  const USER_DEFAULT_AVATAR_URL = '/assets/tenant/defaults/avatar.png';
  const lastDigitOfUserId: number =
    (rawId(userId) || 0) % USER_AVATAR_COLORS.length;
  const isDefaultAvatar = avatarUrl === USER_DEFAULT_AVATAR_URL;

  function handleUserDetailAction(event) {
    event.preventDefault();
    event.stopPropagation();
    PubSub.publish(eventNames.OPEN_PROFILE_PANEL, {
      userId,
      lastElementRef: userAvatarRef,
    });

    trackEvent(TrackingEvent.PROFILE_PANEL_TAB_OPENED);
  }

  const extraProps = shouldOpenProfile
    ? {
        className: cx('user-avatar__container'),
        'aria-label': t('Open profile tab'),
        'aria-hidden': false,
        ref: userAvatarRef,
        role: 'button',
        tabIndex: 0,
        onClick: handleUserDetailAction,
        onKeyDown: (event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleUserDetailAction(event);
          }
        },
      }
    : { className: cx('user-avatar__container') };

  return (
    <div data-qa="user-avatar__container" aria-hidden {...extraProps}>
      <Avatar
        alt={alt || ''}
        backgroundColor={USER_AVATAR_COLORS[lastDigitOfUserId]}
        className={className}
        name={username}
        size={size}
        src={isDefaultAvatar ? '' : avatarUrl || ''}
        srcSet={isDefaultAvatar ? '' : srcSet}
      />
    </div>
  );
};

export default UserAvatar;
