import { useEffect, useState } from 'react';
/* eslint-disable import/prefer-default-export */
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize);
    // We should look into debouncing/throttling this to improve resize performance.
    // This triggers a lot of re-renders when the window is resized

    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  return windowSize;
};
