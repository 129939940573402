import React from 'react';
import classNames from 'classnames/bind';
import { Tooltip } from '@thinkific/toga-react';

import UserAvatar from 'components/UserAvatar';

import styles from './CommunityUsersAvatars.module.scss';

const cx = classNames.bind(styles);

interface CommunityUser {
  id: string;
  fullName: string;
  profile: {
    avatarUrl: string;
  };
}

interface CommunityUsersAvatarsProps {
  className?: string;
  text?: string;
  tooltipTitle: string;
  totalUsers: number;
  users?: CommunityUser[];
}

const CommunityUsersAvatars: React.FC<CommunityUsersAvatarsProps> = ({
  className,
  text = '',
  tooltipTitle,
  totalUsers,
  users,
}) => {
  if (!users) return null;

  return (
    <div className={cx('community-users__wrapper', 'mb-4', className)}>
      {users?.map((user, index) => (
        <div
          className={cx('community-users__avatar-container')}
          key={user.id}
          style={{ zIndex: Math.abs(index - users.length) }}
          aria-hidden
        >
          <Tooltip enterTouchDelay={0} title={user.fullName}>
            <UserAvatar
              avatarUrl={user.profile.avatarUrl}
              className={cx('community-users__avatar')}
              size="xs"
              srcSet={`${user.profile.avatarUrl}?width=40 1x, ${user.profile.avatarUrl}?width=40&dpr=2 2x, ${user.profile.avatarUrl}?width=40&dpr=3 3x`}
              userId={user.id}
              username={user.fullName || ''}
            />
          </Tooltip>
        </div>
      ))}
      {totalUsers > users?.length && (
        <Tooltip enterTouchDelay={0} title={tooltipTitle}>
          <span
            className={cx('community-users__total')}
            data-qa="community-users__total"
            aria-hidden
          >
            +{totalUsers - users?.length} {text}
          </span>
        </Tooltip>
      )}
      <span className="sr-only">{tooltipTitle}</span>
    </div>
  );
};

export default CommunityUsersAvatars;
