import { gql } from '@apollo/client';

export const CREATE_SPACE_MUTATION = gql`
  mutation CreateCommunitySpace($input: CreateCommunitySpaceInput!) {
    createCommunitySpace(input: $input) {
      userErrors {
        code
        message
      }
      communitySpace {
        id
      }
    }
  }
`;

export const UPDATE_SPACE_MUTATION = gql`
  mutation UpdateCommunitySpace($input: UpdateCommunitySpaceInput!) {
    updateCommunitySpace(input: $input) {
      userErrors {
        code
        message
      }
      communitySpace {
        id
      }
    }
  }
`;

export const DELETE_SPACE_MUTATION = gql`
  mutation DeleteCommunitySpace($input: DeleteCommunitySpaceInput!) {
    deleteCommunitySpace(input: $input) {
      userErrors {
        code
        message
      }
    }
  }
`;

export const REORDER_SPACE_MUTATION = gql`
  mutation ReorderCommunitySpace($input: ReorderCommunitySpaceInput!) {
    reorderCommunitySpace(input: $input) {
      userErrors {
        code
        message
      }
    }
  }
`;
