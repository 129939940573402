import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Dropdown, Item, Menu } from 'koba/components/Dropdown';
import TrackingEvent from 'constants/trackingEvents';
import { Caption } from 'koba/components/Typography';
import Button from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import UserAvatar from 'components/UserAvatar';
import { useCommunityContext } from 'components/CommunityContext';
import { routes, getSettingsUrl } from 'utils/routerHelpers';
import { isAdmin } from 'utils/userHelpers';
import styles from './CurrentUserDropdown.module.scss';

const cx = classNames.bind(styles);

interface CurrentUserDropdownProps {
  isMobile?: boolean;
  isTextDark?: boolean;
}

export const CurrentUserDropdown: React.FC<CurrentUserDropdownProps> = ({
  isMobile = false,
  isTextDark = false,
}) => {
  const { t } = useTranslation();
  const { trackEvent, currentUser, community } = useCommunityContext();
  const { id, avatarUrl, fullName } = currentUser;

  const goTo = (route: string, event?: TrackingEvent) => {
    if (event) trackEvent(event, {});
    window.location.href = route;
  };

  return (
    <Dropdown>
      {({ open, toggleMenu }) => (
        <>
          {/* @ts-ignore */}
          <Button
            appearance="knockout"
            ariaLabel={t('Menu options for {{fullName}}', {
              fullName,
            })}
            className={cx('current-user', {
              'current-user--dark-text': isTextDark,
            })}
            dataQA="menu-options-homepage__btn"
            onClick={toggleMenu}
          >
            <Caption className={cx('current-user__name')}>{fullName}</Caption>
            <UserAvatar
              avatarUrl={avatarUrl || ''}
              className={cx('current-user__avatar-container')}
              size="xs"
              srcSet={`${avatarUrl}?width=32 1x, ${avatarUrl}?width=32&dpr=2 2x, ${avatarUrl}?width=32&dpr=3 3x`}
              userId={id}
              username={fullName || ''}
            />

            <Icon name={open ? 'caret-fill-up' : 'caret-fill-down'} />
          </Button>
          <Menu
            dataQA="menu-options__dropdown"
            open={open}
            position={isMobile ? 'absolute' : 'right'}
          >
            {isAdmin(currentUser) ? (
              <>
                <Item
                  title={t(
                    'components-currentUserDropdown-community_settings_button',
                    'Community Settings'
                  )}
                >
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() => goTo(getSettingsUrl(community.id))}
                  >
                    {t(
                      'components-currentUserDropdown-community_settings_button',
                      'Community Settings'
                    )}
                  </button>
                </Item>
                <Item
                  title={t(
                    'components-currentUserDropdown-admin_dashboard_button',
                    'Admin Dashboard'
                  )}
                >
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() => goTo(routes.admin)}
                  >
                    {t(
                      'components-currentUserDropdown-admin_dashboard_button',
                      'Admin Dashboard'
                    )}
                  </button>
                </Item>
                <Item
                  title={t(
                    'components-currentUserDropdown-my_profile_button',
                    'My Profile'
                  )}
                >
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() =>
                      goTo(routes.myAccount, TrackingEvent.MY_ACCOUNT)
                    }
                  >
                    {t(
                      'components-currentUserDropdown-my_profile_button',
                      'My Profile'
                    )}
                  </button>
                </Item>
                <Item title={t('common-log_out', 'Log Out')}>
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() => goTo(routes.signOut)}
                  >
                    {t('common-log_out', 'Log Out')}
                  </button>
                </Item>
              </>
            ) : (
              <>
                <Item
                  title={t(
                    'components-currentUserDropdown-my_profile_button',
                    'My Profile'
                  )}
                >
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() =>
                      goTo(routes.myAccount, TrackingEvent.MY_ACCOUNT)
                    }
                  >
                    {t(
                      'components-currentUserDropdown-my_profile_button',
                      'My Profile'
                    )}
                  </button>
                </Item>
                <Item title={t('common-log_out', 'Log Out')}>
                  <button
                    className={cx('current-user__button')}
                    type="button"
                    onClick={() => goTo(routes.signOut)}
                  >
                    {t('common-log_out', 'Log Out')}
                  </button>
                </Item>
              </>
            )}
          </Menu>
        </>
      )}
    </Dropdown>
  );
};

export default CurrentUserDropdown;
