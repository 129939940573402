import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';

import styles from './SpaceDetailsSkeleton.module.scss';

const cx = classNames.bind(styles);

interface SpaceDetailsSkeletonProps {
  hideImageSkeleton?: boolean;
}

const SpaceDetailsSkeleton: React.FC<SpaceDetailsSkeletonProps> = ({
  hideImageSkeleton,
}) => {
  return (
    <div
      className={cx('space-details-skeleton__container')}
      data-qa="space-details-skeleton"
    >
      {!hideImageSkeleton && <SkeletonLine height="147px" width="100%" />}
      <div className={cx('space-details-skeleton__body')}>
        <SkeletonLine height="16px" width="40%" />
        <div className={cx('space-details-skeleton__description')}>
          <SkeletonLine height="16px" width="60%" />
          <SkeletonLine height="16px" width="70%" />
          <SkeletonLine height="16px" width="80%" />
        </div>
      </div>
    </div>
  );
};

export default SpaceDetailsSkeleton;
