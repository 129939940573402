/* eslint-disable no-console */
import {
  failureToastMessage,
  genericToastAlert,
  successToastMessage,
} from './toastHelpers';

const isValidText = (text?: string): boolean => !!text && text.trim() !== '';

const insertAtPosition = (
  string: string,
  position: number,
  insertValue: string
): string => {
  const leftSubString = string.slice(0, position);
  const rightSubString = string.slice(position);
  return leftSubString + insertValue + rightSubString;
};

const oldCopyToClipboard = (
  content: string,
  successMessage: string,
  failureMessage = ''
) => {
  try {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    successToastMessage(successMessage);
  } catch {
    if (failureMessage) {
      failureToastMessage(failureMessage);
    } else {
      genericToastAlert();
    }
  }
};

const newCopyToClipboard = async (
  content: string,
  successMessage: string,
  failureMessage = ''
) => {
  try {
    await navigator.clipboard.writeText(content);

    successToastMessage(successMessage);
  } catch {
    if (failureMessage) {
      failureToastMessage(failureMessage);
    } else {
      genericToastAlert();
    }
  }
};

const copyToClipboard = (
  content: string,
  successMessage: string,
  failureMessage = ''
): void => {
  if (!navigator.clipboard) {
    oldCopyToClipboard(content, successMessage, failureMessage);
  } else {
    newCopyToClipboard(content, successMessage, failureMessage);
  }
};

export { isValidText, insertAtPosition, copyToClipboard };
