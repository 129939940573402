import React, { useContext, useEffect, useState, ReactNode } from 'react';
import { useQuery } from '@apollo/client';

import { MAIN_QUERY } from 'utils/queries';
import { rawId } from 'utils/rawId';
import { isModerator } from 'utils/userHelpers';
import { EVENT_USER_ROLES } from 'utils/constants';

import FacebookPixelScript from 'components/FacebookPixelScript';
import FontProvider from 'components/FontProvider';
// Right now the toga definitions are created automatically
// and they don't cover context providers
// @ts-ignore
import { ThemeContext } from '@thinkific/toga-react';

import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { initializeFeatureFlags } from 'utils/featureFlags';
import { isTextDark } from 'utils/colors';
import PageSkeleton from 'components/PageSkeleton';
import { CommunityContext as CommunityContextInterface } from 'interfaces/community';
import { TrackEvent } from 'types/analytics';
import { features } from 'constants/featureFlags';
import { TrackingProperty } from 'constants/trackingEvents';
import { useRouter } from 'next/router';

const THINKIFIC_TRACKER_NAME = 'thinkificGA';
const TENANT_TRACKER_NAME = 'tenantGA';

interface CommunityContextProviderProps {
  children: ReactNode;
  communityId: string;
}

const CommunityContext = React.createContext({} as CommunityContextInterface);

const useCommunityContext = (): CommunityContextInterface =>
  useContext(CommunityContext);

// initializes thinkific's and tenant's GA (if provided)
export const initializeGoogleAnalytics = (tenantGAKey: string): void => {
  const tenantGA = getTenantGA(tenantGAKey);

  ReactGA.initialize([
    {
      trackingId: process.env.GOOGLE_ANALYTICS_KEY || '',
      gaOptions: { name: THINKIFIC_TRACKER_NAME },
    },
    ...tenantGA,
  ]);

  ReactGA.pageview('communities', [
    THINKIFIC_TRACKER_NAME,
    ...(tenantGAKey ? [TENANT_TRACKER_NAME] : []),
  ]);
};

const initializeGoogleAnalytics4 = (tenantGAMeasurementId: string): void => {
  ReactGA4.initialize(tenantGAMeasurementId);
  ReactGA4.send('pageview');
};

interface TenantGAData {
  trackingId: string;
  gaOptions: { name: string };
}

export const getTenantGA = (tenantGAKey: string): Array<TenantGAData> =>
  tenantGAKey
    ? [
        {
          trackingId: tenantGAKey,
          gaOptions: { name: TENANT_TRACKER_NAME },
        },
      ]
    : [];

interface ThinkificAnalyticsData {
  track(event: string, options: unknown): void;
}

const CommunityProvider: React.FC<CommunityContextProviderProps> = ({
  children,
  communityId,
}) => {
  const router = useRouter();
  const [thinkificAnalytics, setThinkificAnalytics] = useState<
    ThinkificAnalyticsData
  >();

  const { loading, data } = useQuery(MAIN_QUERY, {
    variables: { communityId },
  });

  const { site, community, me: currentUser } = data || {};

  const mentionableUsers = data?.community?.mentionableUsers;
  const communityUsers = data?.community?.communityUsers;
  const { filestackPolicy: filestackSecurity } = site || {};
  const featureFlags =
    site && site.featureFlags ? initializeFeatureFlags(site.featureFlags) : {};

  useEffect(() => {
    if (site && process.env.NODE_ENV === 'production') {
      initializeGoogleAnalytics(site.googleAnalyticsKey);
    }
  }, [site && site.googleAnalyticsKey]);

  useEffect(() => {
    if (
      site?.integrations?.gaMeasurementId &&
      process.env.NODE_ENV === 'production'
    ) {
      initializeGoogleAnalytics4(site.integrations.gaMeasurementId);
    }
  }, [site?.integrations?.gaMeasurementId]);

  useEffect(() => {
    const loadAnalytics = async () => {
      // Importing the ThinkificAnalytics library with SSR throws errors
      // which is why this util gets imported dynamically only on the client.
      const getThinkificAnalytics = (await import('utils/analytics')).default;
      const analytics = getThinkificAnalytics(
        process.env.MIXPANEL_TOKEN,
        site.trackingKey
      );

      setThinkificAnalytics(analytics);
    };

    if (
      site &&
      !thinkificAnalytics &&
      featureFlags[features.STUDENT_EVENT_TRACKING]
    ) {
      if (process.env.MIXPANEL_TOKEN) {
        loadAnalytics();
      } else {
        // eslint-disable-next-line no-console
        console.warn('No MIXPANEL_TOKEN provided. Skipping event tracking');
      }
    }
  }, [site]);

  useEffect(() => {
    if (currentUser && currentUser.locale) {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('lang', currentUser.locale);
    }
  }, [currentUser]);

  if (loading || !data) {
    return <PageSkeleton />;
  }

  const trackEvent: TrackEvent = (event, eventProperties) => {
    if (!thinkificAnalytics) {
      return null;
    }

    const { SITE_ADMIN, STUDENT } = EVENT_USER_ROLES;
    const userRole = isModerator(currentUser.roles)
      ? SITE_ADMIN
      : currentUser.roles.join(', ');

    const baseProperties = {
      [TrackingProperty.COMMUNITY_ID]: rawId(communityId),
      [TrackingProperty.COMMUNITY_ID_ENCODED]: communityId,
      [TrackingProperty.COMMUNITY_NAME]: community.name,
      [TrackingProperty.TENANT_ID]: rawId(site.id),
      [TrackingProperty.TENANT_ID_ENCODED]: site.id,
      [TrackingProperty.USER_ID]: rawId(currentUser.id),
      [TrackingProperty.USER_ID_ENCODED]: currentUser.id,
      [TrackingProperty.USER_ROLE]: userRole || STUDENT,
    };

    const spaceId = rawId(router?.query?.spaceId as string);
    if (spaceId) {
      baseProperties[TrackingProperty.SPACE_ID] = spaceId;
    }

    return thinkificAnalytics.track(event, {
      ...baseProperties,
      ...eventProperties,
    });
  };

  const facebookPixelCode = site.integrations.facebookPixelCode || '';
  // Extract the Facebook Pixel ID from the script
  const matchResult =
    facebookPixelCode.match(/fbq\('init', '([0-9]+)'\)/) || '';
  const facebookPixelId =
    matchResult.length === 2 ? Number(matchResult[1]) : null;

  const theme = {
    color: {
      // primary: community?.primaryColor, // Disabling until PPG-161 is complete
    },
    button: {
      allCaps: false,
    },
    loader: {
      color: isTextDark(community?.primaryColor) ? 'black' : 'white',
    },
  };

  return (
    <CommunityContext.Provider
      value={{
        site,
        community,
        communityUsers,
        mentionableUsers,
        currentUser: {
          ...currentUser,
          isModerator: isModerator(currentUser.roles),
        },
        trackEvent,
        filestackSecurity,
        featureFlags,
      }}
    >
      <ThemeContext.Provider value={theme}>
        {facebookPixelId && <FacebookPixelScript id={facebookPixelId} />}
        <FontProvider font={community.font} />
        {children}
      </ThemeContext.Provider>
    </CommunityContext.Provider>
  );
};

export { CommunityContext, CommunityProvider, useCommunityContext };
