import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { useCommunityContext } from 'components/CommunityContext';
import PageSkeleton from 'components/PageSkeleton';
import { pathnames, goToHome } from 'utils/routerHelpers';
import { canAccess } from 'utils/userHelpers';
import { USER_ROLES } from 'utils/constants';

interface RedirectUserProps {
  children: ReactNode;
}

const RedirectUser: React.FC<RedirectUserProps> = ({ children }) => {
  const {
    community: { id },
    currentUser,
  } = useCommunityContext();
  const router = useRouter();

  const studentWhitelistedPaths = [
    pathnames.home,
    pathnames.following,
    pathnames.spaces.space,
    pathnames.post,
    pathnames.events.events,
    pathnames.events.event,
    pathnames.search,
  ];

  const isWhitelisted = studentWhitelistedPaths.includes(router.pathname);

  // Send user to Home if they are not members
  if (
    !canAccess(
      [
        USER_ROLES.SITE_OWNER_SITE_ADMIN,
        USER_ROLES.SITE_ADMIN,
        USER_ROLES.PARTNER,
      ],
      currentUser
    ) &&
    !isWhitelisted
  ) {
    goToHome(router, id);
    return <PageSkeleton />;
  }

  return <>{children}</>;
};

export default RedirectUser;
