/**
 * Returns the numeric ID of a base-64 encoded GraphQL ID.
 * Expects the decoded string to be of shape [Object]-[NumericID]
 * For example, Tenant-7 encoded in base64.
 *
 * @param {string} encodedId - A base-64 encoded ID string
 */

export const rawId = (encodedId: string): number | null => {
  let decoded;
  try {
    decoded = atob(encodedId);
  } catch {
    decoded = null;
  }

  const parts = decoded ? decoded.split('-') : [];
  if (parts.length !== 2) {
    return null;
  }

  return Number(parts[1]);
};
