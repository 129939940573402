import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';

import CommunityUsersAvatars from 'components/CommunityUsers/components/CommunityUsersAvatars';
import { LIVE_EVENT_QUERY } from 'schema/Event/queries';
import {
  LiveEventQuery,
  LiveEventQueryVariables,
} from '__generated__/graphql/legacy/graphql';
import { genericToastAlert } from 'utils/toastHelpers';
import { Loader, Button } from '@thinkific/toga-react';
import { useCommunityContext } from 'components/CommunityContext';
import { goToEvent } from 'utils/routerHelpers';

import styles from './EventInterestedUsers.module.scss';

const cx = classNames.bind(styles);

interface EventInterestedUser {
  id: string;
  fullName: string;
  profile: {
    avatarUrl: string;
  };
}

interface EventInterestedUsersProps {
  eventId: string;
}

const EventInterestedUsers: React.FC<EventInterestedUsersProps> = ({
  eventId,
}) => {
  const { t } = useTranslation();
  const { currentUser, community } = useCommunityContext();
  const router = useRouter();

  const handleGoToEventPage = (e) => {
    e?.preventDefault();

    if (eventId && community?.id) {
      goToEvent(router, eventId, community.id);
    }
  };

  const { data, loading } = useQuery<LiveEventQuery, LiveEventQueryVariables>(
    LIVE_EVENT_QUERY,
    {
      variables: {
        eventId,
      },
      notifyOnNetworkStatusChange: true,
      onError: () => {
        genericToastAlert();
      },
    }
  );

  const liveEvent = data?.site?.liveEvent;
  const totalUsers = liveEvent?.interestedUsers?.totalCount || 0;
  const interestedUsersEdges = liveEvent?.interestedUsers?.edges;

  let interestedUsers = [] as EventInterestedUser[] | undefined;

  interestedUsers =
    interestedUsersEdges
      ?.map(
        (edge): EventInterestedUser => {
          return {
            id: edge?.node?.id || '',
            fullName: edge?.node?.fullName || '',
            profile: {
              avatarUrl: edge?.node?.profile?.avatarUrl || '',
            },
          };
        }
      )
      .filter((user) => user.id !== currentUser.id) || [];

  // Makes sure the first UserAvatar shown is the current user logged
  if (liveEvent?.isInterested) {
    interestedUsers?.unshift({
      id: currentUser.id,
      fullName: currentUser.fullName,
      profile: { avatarUrl: currentUser.avatarUrl },
    });
  }

  // // if interestedUsers length is bigger than 4 it means that the currentUser
  // // wasn't returned by the USERS_QUERY and we need to display it + 3 users
  if (interestedUsers.length > 4) interestedUsers = interestedUsers.slice(0, 4);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className={cx('event-interested-users__container')}
          data-qa="event-interested-users"
        >
          <CommunityUsersAvatars
            className={cx('event-interested-users')}
            text={t('components-events-eventPanel-interested', 'interested')}
            tooltipTitle={t(
              'components-eventInterestedUsers-total_member_count_title',
              '{{userCount}} interested members',
              { userCount: totalUsers }
            )}
            totalUsers={totalUsers}
            users={interestedUsers}
          />
          {totalUsers > interestedUsers.length && (
            <Button
              appearance="accessible-link"
              className={cx('event-panel__view-all')}
              data-qa="event-panel__view-all"
              href={`/communities/${community?.id}/events/${eventId}/`}
              type="button"
              onClick={handleGoToEventPage}
            >
              {t('common-view-all', 'view all')}
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default EventInterestedUsers;
