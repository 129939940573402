import { toaster } from '@thinkific/toga-react';
import { i18n } from 'next-i18next';

type ToastMessage = (content: string) => void;
type ToastError = () => void;

export const genericToastAlert: ToastError = () => {
  toaster.alert(i18n?.t('Something went wrong. Please try again.'), {
    autoClose: false,
  });
};

export const successToastMessage: ToastMessage = (content) => {
  toaster.notice(content, {
    autoClose: true,
  });
};

export const failureToastMessage: ToastMessage = (content) => {
  toaster.alert(content, {
    autoClose: false,
  });
};

export const infoToastMessage: ToastMessage = (content) => {
  toaster.info(content, {
    autoClose: true,
  });
};
