import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Button, Icon } from '@thinkific/toga-react';
import {
  goToEvents,
  goToFollowing,
  goToMembers,
  goToHome,
  pathnames,
  goToSearch,
} from 'utils/routerHelpers';
import { isAdmin } from 'utils/userHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { features } from 'constants/featureFlags';
import TrackingEvent from 'constants/trackingEvents';

import { useSiteFeaturesContext } from 'components/SiteFeaturesContext';
import styles from '../CommunityActionsList.module.scss';

const cx = classNames.bind(styles);

interface CommonActionsProps {
  handleMobileNavClick?: () => void;
}

const CommonActions: React.FC<CommonActionsProps> = ({
  handleMobileNavClick,
}) => {
  const { t } = useTranslation();
  const {
    community,
    currentUser,
    communityUsers,
    featureFlags,
    trackEvent,
  } = useCommunityContext();
  const { siteFeatures } = useSiteFeaturesContext();
  const isSearchEnabled = featureFlags[features.COMMUNITIES_SEARCH];
  const communityEventsEnabled = siteFeatures?.communityEvents?.isEnabled;

  const router = useRouter();

  const handleClick = (event: Event) => {
    event.preventDefault();
    if (handleMobileNavClick) handleMobileNavClick();
  };

  return (
    <div className={cx('common-actions__wrapper')}>
      {isSearchEnabled && (
        <Button
          appearance="knockout"
          className={cx('common-actions__button', {
            'is-active': router.pathname === pathnames.search,
          })}
          data-qa="main-search_button"
          href={`/communities/${community.id}/search/`}
          type="button"
          onClick={(event: Event) => {
            handleClick(event);
            trackEvent(TrackingEvent.GO_TO_SEARCH, {});
            goToSearch(router, community.id);
          }}
        >
          <Icon name="magnifying-glass" aria-hidden />
          {t('pages-search_title', 'Search')}
        </Button>
      )}
      <Button
        appearance="knockout"
        className={cx('common-actions__button', {
          'is-active': router.pathname === pathnames.home,
        })}
        data-qa="main-home_button"
        href={`/communities/${community.id}/`}
        type="button"
        onClick={(event: Event) => {
          handleClick(event);
          goToHome(router, community.id);
        }}
      >
        <Icon name="view-grid" aria-hidden />
        {t('pages-home_title', 'Home')}
      </Button>

      <Button
        appearance="knockout"
        className={cx('common-actions__button', {
          'is-active': router.pathname === pathnames.following,
        })}
        data-qa="main-following__button"
        href={`/communities/${community.id}/following/`}
        type="button"
        onClick={(event) => {
          handleClick(event);
          goToFollowing(router, community.id);
        }}
      >
        <Icon name="star-fill" aria-hidden />
        {t('pages-following_title', 'Following')}
      </Button>

      {isAdmin(currentUser) && (
        <Button
          appearance="knockout"
          className={cx('common-actions__button', {
            'is-active': router.pathname === pathnames.members,
          })}
          data-qa="main-members_button"
          href={`/communities/${community.id}/members/`}
          type="button"
          onClick={(event) => {
            handleClick(event);
            goToMembers(router, community.id);
          }}
        >
          <Icon name="people" aria-hidden />
          {t('pages-members_title', 'Members')}
          <span className={cx('common-actions__members-count')}>
            &nbsp; {`(${communityUsers.totalCount})`}
          </span>
        </Button>
      )}

      {communityEventsEnabled && (
        <Button
          appearance="knockout"
          className={cx('common-actions__button', {
            'is-active': router.pathname === pathnames.events.events,
          })}
          data-qa="main-event__button"
          href={`/communities/${community.id}/events/`}
          type="button"
          onClick={(event) => {
            handleClick(event);
            goToEvents(router, community.id);
          }}
        >
          <Icon name="system-calendar" aria-hidden />
          {t('pages-events_title', 'Events')}
        </Button>
      )}
    </div>
  );
};

export default CommonActions;
