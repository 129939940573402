import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Icon.module.scss';

import { availableIcons } from './constants';

const cx = classNames.bind(styles);

const Icon = ({ ariaLabel, className, name, color }) => {
  const classes = cx('toga-icon', `toga-icon-${name}`, 'icon', className);

  return (
    <i
      aria-hidden={!ariaLabel}
      aria-label={ariaLabel}
      className={classes}
      data-qa={`toga-icon-${name}`}
      style={{ color }}
    />
  );
};

Icon.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  color: PropTypes.string,
  name: PropTypes.oneOf(availableIcons).isRequired,
};

Icon.defaultProps = {
  ariaLabel: null,
  className: null,
  color: null,
};

export default Icon;
