import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Header from '../Header';
import {
  DROPDOWN_DIMENSIONS_PROP_TYPE,
  DROPDOWN_DIMENSIONS_DEFAULT_PROP,
} from '../constants';

import styles from '../Dropdown.module.scss';

const cx = classNames.bind(styles);

const Menu = (props) => {
  const {
    children,
    className,
    dataQA,
    dimensions,
    headerContent,
    open,
    position,
    width,
  } = props;

  const localClassName = cx('dropdown__menu', className, {
    'is-visible': open,
    'is-hidden': !open,
    'dropdown__menu-right': position === 'right',
  });

  if (width) {
    console.warn("'width' will be removed from Dropdown/Menu component"); // eslint-disable-line no-console
  }

  let style = width;
  if (dimensions.menu) {
    style = dimensions.menu;
  }

  return (
    <div className={localClassName} data-qa={dataQA} style={style}>
      {headerContent && <Header content={headerContent} />}
      <ul className={cx('dropdown__list')}>{children}</ul>
    </div>
  );
};

Menu.propTypes = {
  /** HTML Node that will appear in the dropdown when clicked */
  children: PropTypes.node,
  /** Override classes if provided * */
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  /** data-qa element */
  dataQA: PropTypes.string,
  /** Object with maxWidth, minWidth and width * */
  dimensions: DROPDOWN_DIMENSIONS_PROP_TYPE,
  /** String that first appear in the hidden menu */
  headerContent: PropTypes.string,
  /** Flag to control if the dropdown menu is open or closed */
  open: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  /** Menu position - use right to right-align the menu */
  position: PropTypes.string,
  /** Menu width */
  width: PropTypes.number,
};

Menu.defaultProps = {
  children: null,
  className: null,
  dataQA: null,
  dimensions: DROPDOWN_DIMENSIONS_DEFAULT_PROP,
  headerContent: '',
  open: false,
  position: null,
  width: null,
};

export default Menu;
