import React from 'react';
import classNames from 'classnames/bind';
import styles from './CircularSpinner.module.scss';

const cx = classNames.bind(styles);

interface CircularSpinnerProps {
  className?: string;
  color?: string;
  variation?: 'default' | 'small' | 'medium' | 'large' | 'xlarge';
}

const CircularSpinner: React.FC<CircularSpinnerProps> = ({
  className,
  color,
  variation = 'default',
}) => (
  <div className={cx(['spinner', `spinner--${variation}`, className])}>
    <div className={cx('circle')}>
      <div
        className={cx('circle-inner')}
        style={{ borderLeftColor: color, borderTopColor: color }}
      />
    </div>
    <div className={cx(['circle', 'circle-2'])}>
      <div
        className={cx('circle-inner')}
        style={{ borderLeftColor: color, borderTopColor: color }}
      />
    </div>
  </div>
);

export default CircularSpinner;
