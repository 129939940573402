import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';

import { useCommunityContext } from 'components/CommunityContext';
import { routes } from 'utils/routerHelpers';
import { features } from 'constants/featureFlags';
import { a11yOnClick } from 'helpers/interactions';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import VisibilityCloak from 'components/VisibilityCloak/VisibilityCloak';
import { CommunityStatuses } from 'types/communityStatuses';

import styles from './CommunityStatusBadge.module.scss';

const cx = classNames.bind(styles);

const CommunityStatusBadge: React.FC = () => {
  const { community, featureFlags, trackEvent } = useCommunityContext();
  const { t } = useTranslation();

  const isCommunitiesAsAProductEnabled =
    featureFlags[features.COMMUNITIES_AS_A_PRODUCT];

  const communityStatusLabel = () => {
    switch (community.status) {
      case CommunityStatuses.PRE_ORDER:
        return t('components-communityStatusBadge-Pre-order', 'Pre-order');
      case CommunityStatuses.PUBLISHED:
        return t('components-communityStatusBadge-live', 'Live');
      default:
        return t('components-communityStatusBadge-draft', 'Draft');
    }
  };

  const handleOnClick = () => {
    trackEvent(TrackingEvent.GO_TO_PUBLISH_COMMUNITY, {
      [TrackingProperty.COMPONENT]: 'CommunityStatusBadge',
    });
  };

  return (
    <VisibilityCloak hide={!isCommunitiesAsAProductEnabled} admins>
      <a
        aria-label={t(
          'components-communityStatusBadge-go_to_publish_community',
          'Go to Community Builder to update its status'
        )}
        className={cx('community-status-badge__link', {
          'is-live': community.status !== 'Draft',
        })}
        href={routes.publishCommunity(community.id)}
        target="__blank"
        {...a11yOnClick<HTMLAnchorElement>(handleOnClick)}
      >
        {communityStatusLabel()}
        <Icon height={16} name="arrow-up-right" width={16} aria-hidden />
      </a>
    </VisibilityCloak>
  );
};

export default CommunityStatusBadge;
