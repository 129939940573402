// If we upgrade eslint, we shouldn't need the no-shadow. It's a bug
/* eslint-disable no-shadow */

enum TrackingEvent {
  // Courses to Communities
  CLICK_ADD_COURSE_TO_SPACE = 'Communities Click Add Course to Space',
  ADD_COURSE_TO_SPACE = 'Communities Add Course to Space',

  CLICK_CHANGE_COURSE_IN_SPACE = 'Communities Click Change Course in Space',
  CHANGE_COURSE_ON_SPACE = 'Communities Change Course on Space',

  CLICK_UNLINK_COURSE_FROM_SPACE = 'Communities Click Unlink Course from Space',
  REMOVE_COURSE_FROM_SPACE = 'Communities Remove Course from Space',

  CLICK_EDIT_COURSE_ENROLLMENTS = 'Communities Click Edit Course Enrollments',
  CLICK_SET_COMMUNITY_ACCESS = 'Communities Click Set Community Access',
  CLICK_EXPAND_LINKED_COURSES = 'Communities Expand Linked Courses',

  GO_TO_COURSE_FROM_COMMUNITY = 'Communities Go From Community To Course',
  GO_TO_COMMUNITY_FROM_COURSE = 'Communities Go From Course to Community',

  // Live Events
  CLICK_COPY_LIVE_EVENT_LINK = 'Communities Click Live Event Copy Link',
  CLICK_CREATE_LIVE_EVENT = 'Communities Click Create Live Event',
  CLICK_EDIT_LIVE_EVENT = 'Communities Click Edit Live Event',
  CLICK_JOIN_LIVE_EVENT = 'Communities Click Join Live Event',
  CLICK_LIVE_EVENT_MEETING_LINK = 'Communities Click Live Event Link',
  CLICK_SEE_ALL_EVENTS = 'Communities Click See All Live Events',
  DELETE_LIVE_EVENT = 'Communities Delete Live Event',
  OPEN_LIVE_EVENT_DETAILS_TAB = 'Communities View Live Event Details',
  PUBLISH_LIVE_EVENT = 'Communities Publish Live Event',
  UPDATE_LIVE_EVENT = 'Communities Publish Edited Live Event',
  CREATE_ATTENDANCE = 'Communities Student Click Interested',
  DELETE_ATTENDANCE = 'Communities Student Click Not Interested',

  // Spaces pricing & packaging
  CLICK_UPGRADE_SUBSCRIPTION_LINK = 'Communities Click Upgrade Subscription Link',

  // Search
  SEARCH_SUBMITTED = 'Communities Search Submitted',

  // Other
  DELETE_POST = 'Communities Delete Post',
  EDIT_POST = 'Communities Edit Post',
  FOLLOW_COMMENT = 'Communities Follow Comment',
  FOLLOW = 'Communities Follow',
  GO_TO_DASHBOARD = 'Communities Go To Dashboard',
  GO_TO_SEARCH = 'Communities Go To Search',
  GO_TO_PROFILE = 'Communities Go To Profile',
  GO_TO_CUSTOMIZE_COMMUNITY = 'Communities Go To Customize Community',
  GO_TO_SET_ACCESS = 'Communities Go To Set Community Access',
  GO_TO_PUBLISH_COMMUNITY = 'Communities Go To Publish Community',

  MY_ACCOUNT = 'Communities My Account',
  NOTIFICATIONS_SAVED = 'Communities Notifications Saved',
  PAGE_VIEW = 'Communities Page View',
  PIN = 'Communities Pin Post',
  POST_CREATED = 'Communities Post Created',
  POST_REACTED = 'Communities Post Reacted',
  UNFOLLOW_COMMENT = 'Communities Unfollow Comment',
  UNFOLLOW = 'Communities Unfollow',
  UNPIN = 'Communities Unpin Post',
  POST_MODAL_OPENED = 'Communities Post Modal Opened',
  SPACE_CREATED = 'Communities Space Created',
  GO_TO_NEW_SPACE = 'Communities Go To New Space',
  SPACE_EDITED = 'Communities Space Edited',
  SPACE_DELETED = 'Communities Space Deleted',
  SPACE_CLICKED = 'Communities Space Clicked',
  EDIT_PROFILE_FROM_MENU_CLICKED = 'Communities Edit Profile Header Clicked',
  EDIT_PROFILE_FROM_PROFILE_PANEL_CLICKED = 'Communities Profile Panel Edit Profile Clicked',
  PROFILE_PANEL_TAB_OPENED = 'Communities Profile Panel Opened',
  PAST_ACTIVITY_CLICKED = 'Communities Profile Panel Past Activities Clicked',
  TRENDING_POST_CLICKED = 'Communities Trending Post Clicked',
}

export enum TrackingProperty {
  ATTACHMENT = 'Attachment',
  COMPONENT = 'Component',
  COMMENT_ID_ENCODED = 'Comment Id (Base64 Encoded)',
  COMMENT_ID = 'Comment Id',
  COMMUNITY_ID_ENCODED = 'Community Id (Base64 Encoded)',
  COMMUNITY_ID = 'Community Id',
  COURSE_SLUG = 'Course Slug',
  COMMUNITY_NAME = 'Community Name',
  INTERACTION_TYPE = 'Type of interaction (added/removed reaction)',
  NOTIFICATIONS_SETTINGS = 'Notifications Settings',
  PINNED_POST = 'Pinned Post',
  PAGE_TYPE = 'Page Type',
  POST_ID_ENCODED = 'Post Id (Base64 Encoded)',
  POST_ID = 'Post Id',
  POST_TYPE = 'Post Type',
  PRODUCT_ID = 'Product Id',
  PRODUCT_TYPE = 'Product Type',
  REACTION_TIMESTAMP = 'Reaction Timestamp',
  TENANT_ID_ENCODED = 'Tenant Id (Base64 Encoded)',
  TENANT_ID = 'Tenant Id',
  USER_ID_ENCODED = 'User Id (Base64 Encoded)',
  USER_ID = 'User Id',
  USER_ROLE = 'User Role',
  VISITOR_ID = 'Visitor ID',
  SPACE_ID = 'Space ID',
  SPACE_NAME = 'Space Name',
  POST_REASSIGNED = 'Post Reassigned',
  WAS_OPENED_FROM_MENTION = 'Was Opened from Mention',
  HAS_MENTION = 'Has mention',
  WINDOW_WIDTH = 'Window Width', // Added for debugging purposes for Appcues flows with high error rates
  EVENT_TYPE = 'Event Type',
  LIVE_EVENT_ID = 'Live Event ID',
  LIVE_EVENT_ID_ENCODED = 'Live Event ID (Base64 Encoded)',
  SENT_EMAILS = 'Sent Emails',
}

export enum ProductTypes {
  COURSE = 'Course',
}

export default TrackingEvent;
