import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames/bind';

import styles from './Dropdown.module.scss';

const cx = classNames.bind(styles) // eslint-disable-line

interface DropdownProps {
  className?: string;
}

type DropdownState = {
  open: boolean;
};

class Dropdown extends Component<DropdownProps, DropdownState> {
  // eslint-disable-next-line react/state-in-constructor
  state: DropdownState = {
    open: false,
  };

  componentDidMount(): void {
    // Starting on React 17, event listeners are not longer attached to
    // the document, they are attached to the root element of the app
    const rootElement = document.getElementById('__next');
    if (rootElement) {
      rootElement.addEventListener('click', this.handleClickOutside);
    }
  }

  componentWillUnmount(): void {
    const rootElement = document.getElementById('__next');
    if (rootElement) {
      rootElement.removeEventListener('click', this.handleClickOutside);
    }
  }

  handleClickOutside = (event: MouseEvent): void => {
    const domNode = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    const { open } = this.state;

    // Closes any dropdown instances as we use other things in the page
    if (!domNode || !domNode.contains(event.target as HTMLElement)) {
      if (open) {
        this.toggleMenu();
      }
    }

    // Close the dropdown when a dropdown item is clicked on
    if (
      domNode?.contains(event.target as HTMLElement) &&
      (event.target as HTMLElement).parentNode !== domNode
    ) {
      if (open) {
        this.toggleMenu();
      }
    }
  };

  toggleMenu = (e?: Event): void => {
    if (e) {
      e.stopPropagation();
    }
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render(): React.ReactNode {
    const { children, className } = this.props;
    const { open } = this.state;

    return (
      <div className={cx('dropdown', className)}>
        {/* @ts-ignore - This might be an actual error */}
        {children({ open, toggleMenu: this.toggleMenu })}
      </div>
    );
  }
}

export default Dropdown;
