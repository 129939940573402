import { RefObject, useEffect, useState } from 'react';

import { eventNames } from 'constants/eventNames';

interface PubSubInfo {
  eventId: string;
  lastElementRef: RefObject<HTMLElement>;
}

interface UseEventPanelReturn {
  isOpen: boolean;
  eventId?: string | null;
  handleCloseEventPanel: () => void;
}

const useEventPanel = (onOpenCallback?: () => void): UseEventPanelReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [eventId, setEventId] = useState<string | null>();
  const [lastFocusedElementRef, setLastFocusedElementRef] = useState<
    RefObject<HTMLElement>
  >();

  useEffect(() => {
    const token = PubSub.subscribe(
      eventNames.OPEN_EVENT_PANEL,
      (msg: string, info: PubSubInfo) => {
        handleOpenEventPanel(info);
      }
    );

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  const handleCloseEventPanel = () => {
    lastFocusedElementRef?.current?.focus();
    setEventId(null);
    setIsOpen(false);
  };

  const handleOpenEventPanel = (info: PubSubInfo) => {
    setLastFocusedElementRef(info.lastElementRef);
    setEventId(info.eventId);
    setIsOpen(true);
    onOpenCallback?.();
  };

  return {
    isOpen,
    eventId,
    handleCloseEventPanel,
  };
};

export default useEventPanel;
