import { gql } from '@apollo/client';
import { PRODUCTS_FRAGMENT } from './fragments';

export const COURSE_FRAGMENT = gql`
  fragment CourseFields on Course {
    id
    name
    description
    cardImageUrl
    enrollmentsCount @include(if: $isModerator)
    resumeCourseUrl
    percentageCompleted
    isPrivate @include(if: $isModerator)
    isHidden @include(if: $isModerator)
    status @include(if: $isModerator)
  }
`;

export const PRODUCT_QUERY = gql`
  query CommunityProductRelations(
    $communityId: ID!
    $relatableId: ID!
    $isModerator: Boolean!
    $relatableType: String
  ) {
    community(id: $communityId) {
      id
      communityProductRelations(
        relatableId: $relatableId
        relatableType: $relatableType
      ) {
        edges {
          cursor
          node {
            id
            product {
              id
              slug
              productable {
                __typename
                ... on Course {
                  ...CourseFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${COURSE_FRAGMENT}
`;

export const PRODUCTS_QUERY = gql`
  query Products($isModerator: Boolean!) {
    site {
      id
      products(filter: { publicationStatus: [published], type: Course }) {
        ...ProductsFields
      }
    }
  }
  ${COURSE_FRAGMENT}
  ${PRODUCTS_FRAGMENT}
`;
