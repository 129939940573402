import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames/bind';

import styles from './Typography.module.scss';

const cx = classNames.bind(styles);

const P: FC<HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...props
}) => {
  const classes = cx('p', className);

  return (
    <p className={classes} {...props}>
      {children}
    </p>
  );
};

export default P;
