import React from 'react';
import { useTranslation } from 'react-i18next';
import { H4, Icon, P, Surface } from '@thinkific/toga-react';
import classNames from 'classnames/bind';
import styles from './ModeratorAccessList.module.scss';

const cx = classNames.bind(styles);

const ModeratorAccessList: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Surface className={cx('moderator-access-list')}>
      <H4>
        {t(
          'components-moderatorPanel-permissions_title',
          'Community Moderator Permissions'
        )}
      </H4>
      <P>
        {t(
          'components-moderatorPanel-permissions_description',
          'Permissions below only apply to the community the moderator is assigned to.'
        )}
      </P>
      <ul>
        <li>
          <Icon
            className={cx('moderator-access-list__allowed')}
            name="checkmark"
          />
          <span>
            {t(
              'components-moderatorPanel-permissions_first_item',
              'Can create, edit, delete, and pin any post in the community'
            )}
          </span>
        </li>
        <li>
          <Icon
            className={cx('moderator-access-list__allowed')}
            name="checkmark"
          />
          <span>
            {t(
              'components-moderatorPanel-permissions_second_item',
              'Can create, edit and reorder Spaces'
            )}
          </span>
        </li>
        <li>
          <Icon
            className={cx('moderator-access-list__banned')}
            name="x-dismiss"
          />
          <span>
            {t(
              'components-moderatorPanel-permissions_third_item',
              'Cannot delete Spaces'
            )}
          </span>
        </li>
        <li>
          <Icon
            className={cx('moderator-access-list__banned')}
            name="x-dismiss"
          />
          <span>
            {t(
              'components-moderatorPanel-permissions_forth_item',
              'Cannot create, edit or delete events'
            )}
          </span>
        </li>
        <li>
          <Icon
            className={cx('moderator-access-list__banned')}
            name="x-dismiss"
          />
          <span>
            {t(
              'components-moderatorPanel-permissions_fifth_item',
              "Cannot add or revoke other users' access to the community"
            )}
          </span>
        </li>
      </ul>
    </Surface>
  );
};

export default ModeratorAccessList;
