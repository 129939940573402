import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { Button } from '@thinkific/toga-react';
import { routes, getSettingsUrl } from 'utils/routerHelpers';

import { Caption } from 'koba/components/Typography';
import TrackingEvent from 'constants/trackingEvents';
import { useCommunityContext } from 'components/CommunityContext';
import { isAdmin } from 'utils/userHelpers';

import UserAvatar from 'components/UserAvatar';
import styles from './MobileMenuUserSection.module.scss';

const cx = classNames.bind(styles);

const MobileMenuUserSection: React.FC = () => {
  const { t } = useTranslation();
  const {
    trackEvent,
    currentUser: { fullName, avatarUrl },
    currentUser,
    community: { id },
  } = useCommunityContext();

  const handleNavigation = (event: TrackingEvent, path: string) => {
    trackEvent(event, {});
    window.location.assign(path);
  };
  const goTo = (route: string, event?: TrackingEvent) => {
    if (event) trackEvent(event, {});
    window.location.href = route;
  };

  const goToMyAccount = (event: Event) => {
    event.preventDefault();
    handleNavigation(TrackingEvent.MY_ACCOUNT, '/account');
  };

  const goToMyDashboard = (event: Event) => {
    event.preventDefault();
    handleNavigation(TrackingEvent.GO_TO_DASHBOARD, '/enrollments');
  };

  return (
    <div className={cx('mobile-menu-user-section__wrapper')}>
      <hr className={cx('mobile-menu-user-section__separator')} />

      <div className={cx('mobile-menu-user-section__info')}>
        <div className={cx('mobile-menu-user-section__avatar-container')}>
          <UserAvatar
            avatarUrl={avatarUrl}
            className={cx('mobile-menu-user-section__avatar')}
            size="xs"
            srcSet={`${avatarUrl}?width=32 1x, ${avatarUrl}?width=32&dpr=2 2x, ${avatarUrl}?width=32&dpr=3 3x`}
            userId={id}
            username={fullName || ''}
          />
        </div>

        <Caption className={cx('current-user__name')}>{fullName}</Caption>
      </div>

      <div className={cx('mobile-menu-user-section__actions')}>
        {isAdmin(currentUser) && (
          <Button
            appearance="knockout"
            className={cx('mobile-menu-user-section__button')}
            onClick={() => goTo(getSettingsUrl(id as string))}
          >
            {t(
              'components-mobileMenuUserSections-community_settings_button',
              'Community Settings'
            )}
          </Button>
        )}
        <Button
          appearance="knockout"
          className={cx('mobile-menu-user-section__button')}
          href="/enrollments"
          onClick={goToMyDashboard}
        >
          {t(
            'components-mobileMenuUserSections-dashboard_button',
            'My Dashboard'
          )}
        </Button>
        <Button
          appearance="knockout"
          className={cx('mobile-menu-user-section__button')}
          href="/account"
          onClick={goToMyAccount}
        >
          {t(
            'components-mobileMenuUserSections-my_profile_button',
            'My Profile'
          )}
        </Button>

        <Button
          appearance="knockout"
          className={cx('mobile-menu-user-section__button')}
          href="/logout"
          onClick={() => goTo(routes.signOut)}
        >
          {t('common-log_out', 'Log Out')}
        </Button>
      </div>
    </div>
  );
};

export default MobileMenuUserSection;
