import React from 'react';
import classNames from 'classnames/bind';
import styles from '../Dropdown.module.scss';

const cx = classNames.bind(styles);

const Header: React.FC<{ content: React.ReactNode }> = ({ content }) => (
  <div className={cx('dropdown__header')}>{content}</div>
);

export default Header;
