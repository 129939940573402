import React from 'react';
import classNames from 'classnames/bind';

import { SkeletonLine } from 'koba/components/SkeletonPost';

import styles from './ProfilePanelSkeleton.module.scss';

const cx = classNames.bind(styles);

const ProfilePanel: React.FC = () => {
  return (
    <div className={cx('skeleton-profile-panel')}>
      <div className={cx('skeleton-profile-panel__avatar')} />
      <SkeletonLine height="40px" width="100%" />
      <SkeletonLine height="20px" width="70%" />
      <SkeletonLine height="20px" width="90%" />
      <SkeletonLine height="20px" width="75%" />
      <SkeletonLine height="20px" width="30%" />
    </div>
  );
};

export default ProfilePanel;
