import React from 'react';
import classNames from 'classnames/bind';
import { Icon } from '@thinkific/toga-react';
import { ButtonWithIconLeft } from 'koba/components/Button';
import { Caption, H1 } from 'koba/components/Typography';
import { useCommunityContext } from 'components/CommunityContext';
import CurrentUserDropdown from 'components/CurrentUserDropdown';
import {
  NotificationPane,
  useIsNotificationPaneEnabled,
} from 'components/NotificationPane';
import { isTextDark } from 'utils/colors';
import TrackingEvent from 'constants/trackingEvents';
import { TFunction } from 'next-i18next';

import CommunityStatusBadge from 'components/CommunityStatusBadge/CommunityStatusBadge';

import styles from './TopBar.module.scss';

const cx = classNames.bind(styles);

const TopBar: React.FC<{ isDashboardLinkVisible?: boolean; t: TFunction }> = ({
  isDashboardLinkVisible = false,
  t,
}) => {
  const { community, currentUser, trackEvent } = useCommunityContext();
  const isNotificationPaneEnabled = useIsNotificationPaneEnabled();

  const color = community.primaryColor;

  const goToDashboard = () => {
    trackEvent(TrackingEvent.GO_TO_DASHBOARD, {});
    window.location.href = '/enrollments';
  };

  return (
    <>
      <header
        className={cx('top-bar', isTextDark(color) ? 'top-bar--dark-text' : '')}
        style={{ backgroundColor: color }}
      >
        <div className={cx('top-bar__container')}>
          <ButtonWithIconLeft
            appearance="knockout"
            aria-label={t(
              'components-topBar-student_dashboard_button',
              'My Dashboard'
            )}
            className={cx(
              'top-bar__link',
              'top-bar__link-dashboard',
              isDashboardLinkVisible ? `top-bar__link-visible` : ''
            )}
            onClick={goToDashboard}
          >
            <Icon name="caret-stroke-left" />
            <Caption className={cx('top-bar-dashboard__title')}>
              {t('components-topBar-student_dashboard_button', 'My Dashboard')}
            </Caption>
          </ButtonWithIconLeft>
          <div className={cx('top-bar__link-container')}>
            <a
              aria-label={community.name}
              className={cx('top-bar__link', 'top-bar__link-title')}
              href={`/communities/${community.id}`}
            >
              <H1 className={cx('top-bar__title')}>{community.name}</H1>
            </a>

            <CommunityStatusBadge />
          </div>
          <div className={cx('top-bar__right-container')}>
            {currentUser && (
              <div className={cx('top-bar__dropdown')}>
                <CurrentUserDropdown isTextDark={isTextDark(color)} />
              </div>
            )}
            {isNotificationPaneEnabled && (
              <NotificationPane className={cx('top-bar__button-hover')} />
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default TopBar;
