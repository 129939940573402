import React, { ReactNode, useContext, createContext } from 'react';
import { useQuery } from '@apollo/client';

import { SITE_FEATURES_QUERY } from 'schema/SiteFeatures/queries';
import { useCommunityContext } from 'components/CommunityContext';
import {
  SiteFeaturesQuery,
  SiteFeaturesQueryVariables,
} from '__generated__/graphql/legacy/graphql';

interface SiteFeaturesContextProviderProps {
  children: ReactNode;
}

export interface SiteFeatures {
  siteFeatures?: {
    communities?: {
      maxCommunitySpaces: number;
    } | null;
    communityEvents?: {
      isEnabled?: boolean | null;
    } | null;
    communityEventNotifications?: {
      isEnabled?: boolean | null;
    } | null;
  } | null;
  communitySpacesTotalCount?: number | null;
  exceededPlanSpaces?: boolean;
}

const SiteFeaturesContext = createContext({} as SiteFeatures);

const useSiteFeaturesContext = (): SiteFeatures =>
  useContext(SiteFeaturesContext);

const SiteFeaturesProvider: React.FC<SiteFeaturesContextProviderProps> = ({
  children,
}) => {
  const {
    community: { id },
  } = useCommunityContext();

  const { data } = useQuery<SiteFeaturesQuery, SiteFeaturesQueryVariables>(
    SITE_FEATURES_QUERY,
    {
      variables: { communityId: id },
    }
  );

  const siteFeatures = data?.site?.features;
  const maxSpacesAllowed = siteFeatures?.communities?.maxCommunitySpaces;
  const communitySectionsEdges = data?.community?.communitySections?.edges;
  const communitySpacesTotalCount =
    communitySectionsEdges &&
    communitySectionsEdges[0]?.node?.communitySpaces?.totalCount;
  const exceededPlanSpaces =
    maxSpacesAllowed && communitySpacesTotalCount
      ? maxSpacesAllowed - communitySpacesTotalCount <= 0
      : false;

  return (
    <SiteFeaturesContext.Provider
      value={{
        siteFeatures,
        communitySpacesTotalCount,
        exceededPlanSpaces,
      }}
    >
      {children}
    </SiteFeaturesContext.Provider>
  );
};

export { SiteFeaturesContext, SiteFeaturesProvider, useSiteFeaturesContext };
