import React from 'react';

import { isAdmin } from 'utils/userHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { useWindowSize } from 'hooks/useWindowSize';
import { BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG } from 'utils/constants';

interface VisibilityCloakProps {
  admins?: boolean;
  hide?: boolean;
  hideXS?: boolean;
  hideSM?: boolean;
  hideMD?: boolean;
}
// https://getbootstrap.com/docs/4.0/utilities/display/
const VisibilityCloak: React.FC<VisibilityCloakProps> = ({
  admins,
  hide,
  hideXS,
  hideSM,
  hideMD,
  children,
}) => {
  const { currentUser } = useCommunityContext();
  const { width } = useWindowSize();

  if (admins && !isAdmin(currentUser)) return null;
  if (hide) return null;
  if (hideXS && width < BREAKPOINT_SM) return null; // Hide on x-small screens
  if (hideSM && width < BREAKPOINT_MD) return null; // Hide on small screens
  if (hideMD && width < BREAKPOINT_LG) return null; // Hide on medium screens

  return <>{children}</>;
};

export default VisibilityCloak;
