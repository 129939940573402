/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@thinkific/toga-react';

import {
  NotificationPane,
  useIsNotificationPaneEnabled,
} from 'components/NotificationPane';
import { ButtonWithIconOnly } from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import styles from './MobileMenu.module.scss';
import MenuTab from './components/MenuTab';

const cx = classNames.bind(styles);

interface MobileMenuProps {
  buttonClassName?: string;
  className?: string;
  children: ReactNode;
  headerTitle?: ReactNode;
  menuTitle?: string;
  rightAccessory?: ReactNode;
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  isPostView: boolean;
  isEventPage?: boolean;
  goToPostListPage: () => void;
}
const MobileMenu: React.FC<MobileMenuProps> = ({
  buttonClassName,
  children,
  className,
  headerTitle,
  isOpen,
  menuTitle,
  onClose,
  onOpen,
  rightAccessory,
  isPostView,
  isEventPage = false,
  goToPostListPage,
}) => {
  const { t } = useTranslation();
  const isNotificationPaneEnabled = useIsNotificationPaneEnabled();

  return (
    <>
      <header className={cx('mobile-menu__wrapper', className)}>
        {isPostView || isEventPage ? (
          <ButtonWithIconOnly
            appearance="knockout"
            ariaLabel={t(
              'common-return_to_previous_page',
              'Return to the previous page'
            )}
            className={cx('mobile-menu__back-button')}
            onClick={goToPostListPage}
          >
            <Icon name="arrow-left" />
          </ButtonWithIconOnly>
        ) : (
          !isOpen && (
            <IconButton
              appearance="utility"
              aria-label={t('Click to open the menu')}
              className={cx('mobile-menu__button', buttonClassName)}
              name="menu"
              title={t('Click to open the menu')}
              onClick={onOpen}
            />
          )
        )}
        {!isOpen && headerTitle && (
          <div className={cx('mobile-menu__header-title')}>{headerTitle}</div>
        )}
        <div
          className={cx('mobile-menu__actions', {
            'is-menu-open': isOpen,
          })}
        >
          {rightAccessory}
          {isNotificationPaneEnabled && (
            <NotificationPane className={cx('mobile-menu__button-hover')} />
          )}
        </div>
      </header>
      <MenuTab
        isOpen={isOpen}
        menuTitle={menuTitle}
        hasOverlay
        showCommunityStatus
        onClose={onClose}
      >
        {children}
      </MenuTab>
    </>
  );
};

export default MobileMenu;
