import React, { useState, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Button } from '@thinkific/toga-react';
import { TFunction } from 'next-i18next';

import Icon from '../../Icon';
import styles from '../Section.module.scss';

const cx = classNames.bind(styles);

interface SectionToggleProps {
  caretType: string;
  caption?: string;
  children: ReactNode;
  title: string;
  toggleClassName?: string;
  toggleTitleClassName?: string;
  toggleContentClassName?: string;
  rightAccessory?: ReactNode;
  t: TFunction;
  startClosed?: boolean;
  onExpand?: () => void;
}

export const SectionToggle: React.FC<SectionToggleProps> = ({
  caretType,
  caption,
  children,
  title,
  toggleClassName,
  toggleTitleClassName,
  toggleContentClassName,
  rightAccessory,
  startClosed = false,
  t,
  onExpand,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(startClosed);

  const toggleSection = (event) => {
    event.preventDefault();

    if (isCollapsed) {
      onExpand?.();
    }

    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={cx('section--toggle-item', toggleClassName)}>
      <div className={cx('section--toggle-item__wrapper')}>
        <Button
          appearance="knockout"
          aria-label={
            isCollapsed
              ? t(
                  'components-section-click_to_expand_aria_label',
                  `Section {{title}} collapsed. Click to expand {{title}} section`,
                  { title }
                )
              : t(
                  'components-section-click_to_collapse_aria_label',
                  `Section {{title}} expanded. Click to collapse {{title}} section`,
                  { title }
                )
          }
          className={cx('section--toggle-item__toggleable', {
            'has-right-accessory': rightAccessory,
          })}
          type="button"
          onClick={toggleSection}
        >
          <span
            className={cx(
              'section--toggle-item__toggleable__title',
              toggleTitleClassName
            )}
          >
            {title}
          </span>
          {caption && !rightAccessory && (
            <span
              className={cx(
                'caption',
                'font-italic',
                'section--toggle-item__caption'
              )}
            >
              {caption}
            </span>
          )}
          <Icon
            className={cx('section--toggle-item__icon')}
            name={
              isCollapsed ? `caret-${caretType}-up` : `caret-${caretType}-down`
            }
          />
        </Button>
        {rightAccessory && (
          <div className={cx('section--toggle-item__actions')}>
            {rightAccessory}
          </div>
        )}
      </div>
      {!isCollapsed && (
        <div
          className={cx(
            'section--toggle-item__toggle-content',
            toggleContentClassName
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};
