import React from 'react';
import { useWindowSize } from 'hooks/useWindowSize';
import MenuTab from 'koba/components/MobileMenu/components/MenuTab';
import { SCREEN_LG } from 'utils/constants';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';
import { H2, IconButton, Input } from '@thinkific/toga-react';
import Button from 'koba/components/Button';
import { useCommunityContext } from 'components/CommunityContext';
import styles from './ModeratorPanel.module.scss';
import ModeratorAccessList from './components/ModeratorAccessList/ModeratorAccessList';

const cx = classNames.bind(styles);

interface ModeratorPanelProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModeratorPanel: React.FC<ModeratorPanelProps> = ({ isOpen, onClose }) => {
  const { community } = useCommunityContext();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const isMobile = width < SCREEN_LG;

  const closeButton = !isMobile && (
    <IconButton
      appearance="utility"
      className={cx('moderator-panel__close')}
      data-qa="moderator-panel__close"
      name="x-dismiss"
      title={t(
        'components-moderatorPanel-close_panel',
        'Close moderator panel'
      )}
      onClick={onClose}
    />
  );

  return (
    <MenuTab
      hasHeader={isMobile}
      hasOverlay={isMobile}
      isOpen={isOpen}
      menuTitle={t('common-Moderator', 'Moderator')}
      size={isMobile ? 'medium' : 'large'}
      slideTo="left"
      wrapperClassName={cx('moderator-panel')}
      onClose={onClose}
    >
      <aside
        aria-label={cx('Moderator Panel', 'Moderator Panel')}
        className={cx('moderator-panel__wrapper')}
        data-qa="moderator-panel__wrapper"
        role="dialog"
      >
        <div className={cx('moderator-panel__header')}>
          <H2>
            {t('components-moderatorPanel-new_moderator', 'New Moderator')}
          </H2>
          {closeButton}
        </div>
        <div className={cx('moderator-panel__search')}>
          <Input
            ariaLabel={cx(
              'components-moderatorPanel-search_input',
              'Moderator panel members search input'
            )}
            extraClasses={cx('moderator-panel__members-search')}
            extraProps={{
              'data-qa': 'moderator-panel__members-search',
              id: 'moderator-panel__members-search',
            }}
            label={t('common-Member', 'Member')}
            type="text"
          />
        </div>
        <ModeratorAccessList />
      </aside>
      <div className={cx('moderator-panel__footer')}>
        <Button
          appearance="default"
          aria-label={t(
            'components-moderatorPanel-assign_moderator_button',
            'Moderator Panel assign moderator button'
          )}
          className={cx('moderator-panel__assign-button')}
          color={community.primaryColor}
          data-qa="members__add-moderator-button"
        >
          {t('Assign Moderator', 'Assign Moderator')}
        </Button>
      </div>
    </MenuTab>
  );
};

export default ModeratorPanel;
