import React, { ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PubSub from 'pubsub-js';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { SPACE_QUERY } from 'schema/Space/queries';
import { SPACE_IMAGE_PLACEHOLDER } from 'utils/constants';
import { genericToastAlert } from 'utils/toastHelpers';
import { eventNames } from 'constants/eventNames';
import Opacity from 'components/Opacity/Opacity';
import { pathnames } from 'utils/routerHelpers';
import { useCommunityContext } from 'components/CommunityContext';
import { features } from 'constants/featureFlags';
import SpaceDetailsSkeleton from './components/SpaceDetailsSkeleton/SpaceDetailsSkeleton';
import SpaceDetailsCard from './components/SpaceDetailsCard/SpaceDetailsCard';

interface SpaceDetailsProps {
  communityId: string;
  className?: string;
  spaceId: string;
  footerAccessory?: ReactNode;
}

const SpaceDetails: React.FC<SpaceDetailsProps> = ({
  communityId,
  spaceId,
  footerAccessory,
  className,
}) => {
  const { t } = useTranslation();
  const { featureFlags } = useCommunityContext();
  const router = useRouter();

  const isCoursesToCommunitiesEnabled =
    featureFlags[features.COURSES_TO_COMMUNITIES];
  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  const { data, error, loading } = useQuery(SPACE_QUERY, {
    variables: { communityId, spaceId, isCoursesToCommunitiesEnabled },
    notifyOnNetworkStatusChange: true,
    skip: !spaceId,
    onError: () => {
      genericToastAlert();
    },
  });

  const hasError = !!(
    error?.graphQLErrors?.length || data?.community?.userErrors?.length
  );

  const space = data?.community?.communitySpace;
  const defaultDescription = t(
    'components-spaceDetails-guideline_convo_starters_placeholder',
    'eg. Guidelines, conversation starters, etc.'
  );
  const isSpacePage = router.pathname === pathnames.spaces.space;
  const opacityValue = !isSpacePage ? 0.6 : 1;

  useEffect(() => {
    setDescription(space?.description || defaultDescription);
    setImageUrl(space?.imageUrl);
  }, [space]);

  useEffect(() => {
    const tokenDescription = PubSub.subscribe(
      eventNames.SPACE_DESCRIPTION_CHANGE,
      (msg: string, value: string) =>
        setDescription(value !== '' ? value : defaultDescription)
    );

    const tokenImage = PubSub.subscribe(
      eventNames.SPACE_IMAGE_CHANGE,
      (msg: string, value: string) =>
        setImageUrl(value || SPACE_IMAGE_PLACEHOLDER)
    );
    return () => {
      PubSub.unsubscribe(tokenDescription);
      PubSub.unsubscribe(tokenImage);
    };
  }, []);

  if (loading) {
    return <SpaceDetailsSkeleton />;
  }

  return (
    <>
      {!hasError && (
        <Opacity value={opacityValue}>
          <SpaceDetailsCard
            className={className}
            description={description}
            footerAccessory={footerAccessory}
            imageAltText={space?.imageAltText}
            imageUrl={imageUrl}
            name={t('Details')}
          />
        </Opacity>
      )}
    </>
  );
};

export default SpaceDetails;
