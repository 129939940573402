import React from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { P } from 'koba/components/Typography';

import { useCommunityContext } from 'components/CommunityContext';
import {
  NUMBER_OF_REACTIONS,
  POSTS_ON_PROFILE_VIEW,
  POST_ORDER_FIELDS,
} from 'utils/constants';
import { POST_LIST_QUERY } from 'schema/Post/queries';
import { ProfilePostSkeletonContainer } from '../ProfilePostSkeleton/ProfilePostSkeleton';
import ProfilePostEmptyState from '../ProfilePostEmptyState/ProfilePostEmptyState';
import ProfilePost from '../ProfilePost/ProfilePost';

import styles from './ProfilePastActivity.module.scss';

const cx = classNames.bind(styles);

interface ProfilePastActivity {
  onClose: () => void;
  userId: string;
  user?: {
    firstName?: string | null;
  } | null;
}

const ProfilePastActivity: React.FC<ProfilePastActivity> = ({
  onClose,
  userId,
  user,
}) => {
  const { t } = useTranslation();
  const { community } = useCommunityContext();

  const { loading: isPostQueryLoading, data: postListData } = useQuery(
    POST_LIST_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip: !userId,
      notifyOnNetworkStatusChange: true,
      variables: {
        communityId: community.id,
        numOfPosts: POSTS_ON_PROFILE_VIEW,
        postOrderField: POST_ORDER_FIELDS.CREATED_AT,
        filter: {
          userId,
        },
        numberOfReactions: NUMBER_OF_REACTIONS,
      },
    }
  );

  const posts = postListData?.community?.posts?.edges || [];

  return (
    <section className={cx('past-activity')}>
      {isPostQueryLoading ? (
        <ProfilePostSkeletonContainer />
      ) : (
        <>
          {posts.length !== 0 ? (
            <>
              <P
                className={cx('past-activity__title')}
                data-qa="past-activity__title"
              >
                {t('Past activity')}
              </P>
              <ul>
                {posts.map(({ node }) => (
                  <ProfilePost
                    author={node.user}
                    createdAt={node.createdAt}
                    id={node.id}
                    key={node.id}
                    title={node.title}
                    onPostClick={onClose}
                  />
                ))}
              </ul>
            </>
          ) : (
            <ProfilePostEmptyState userName={user?.firstName || ''} />
          )}
        </>
      )}
    </section>
  );
};

export default ProfilePastActivity;
