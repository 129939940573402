import { AllFeatureFlags } from 'types/featureFlag';
import { FeatureFlags } from 'interfaces/featureFlag';

export const initializeFeatureFlags = (
  features: AllFeatureFlags
  // labFeatures: LabFeatures <- for Lab Experiments, see example below in comments
): FeatureFlags => {
  const flags: FeatureFlags = {};

  for (let i = 0; i < features?.length; i += 1) {
    const currentFeature = features[i];

    if (currentFeature.length) {
      const key = currentFeature[0].toLowerCase();
      const isEnabled =
        typeof currentFeature[1] === 'boolean' ? currentFeature[1] : false;

      /**
       * Lab Experiments - Overrides feature toggle
       * More details see past history of this file
       */
      // switch (key) {
      //   case featureNames.COMMUNITIES_REPLIES_TO_COMMENTS:
      //     isEnabled = labFeatures?.reply_to_comments ? labFeatures.reply_to_comments?.isEnabled : isEnabled;
      //     break;
      // }
      flags[key] = isEnabled;
    } else {
      // eslint-disable-next-line no-console
      console.warn('Feature missing key or enabled value', currentFeature);
    }
  }
  return flags;
};
