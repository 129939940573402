import React from 'react';
import classNames from 'classnames/bind';

import CommunityActionsList from 'components/CommunityActionsList';

import styles from './CommunityActions.module.scss';

const cx = classNames.bind(styles);

interface CommunityActionsProps {
  handleMobileNavClick?: () => void;
  className?: string;
}

const CommunityActions: React.FC<CommunityActionsProps> = ({
  handleMobileNavClick,
  className,
}) => {
  return (
    <div className={cx('community-actions__wrapper', className)}>
      <CommunityActionsList handleMobileNavClick={handleMobileNavClick} />
    </div>
  );
};

export default CommunityActions;
