import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const FONTS = [
  'Source Sans Pro',
  'Droid Serif',
  'Lato',
  'Montserrat',
  'Open Sans',
  'Raleway',
];

const FontProvider: React.FC<{ font: string }> = ({ font }) => {
  let fontFamily = FONTS[0];
  if (FONTS.includes(font)) {
    fontFamily = font;
  }

  return (
    <Head>
      <link
        href={`https://fonts.googleapis.com/css?family=${encodeURI(
          fontFamily
        )}:300,400,600,700`}
        rel="stylesheet"
      />
      <style>
        {`body, input, button, textarea, .emoji-mart { font-family: ${fontFamily}; }`}
      </style>
    </Head>
  );
};

FontProvider.propTypes = {
  font: PropTypes.oneOf(FONTS).isRequired,
};

export default FontProvider;
