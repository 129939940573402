import App from 'next/app';
import Router from 'next/router';
import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import dynamic from 'next/dynamic';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { toaster, ToastContainer } from '@thinkific/toga-react';

import SiteLayout from 'components/Layouts/SiteLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import RedirectUser from 'components/RedirectUser';
import { CommunityProvider } from 'components/CommunityContext';
import { SiteFeaturesProvider } from 'components/SiteFeaturesContext';
import { appWithTranslation, i18n } from 'next-i18next';
import { genericToastAlert } from 'utils/toastHelpers';
import { setContext } from '@apollo/client/link/context';

import nextI18NextConfig from '../next-i18next.config.js';

import './styles.scss';

const graphqlApiPath = '/api/graphql';

// Analytics only works when the browser has loaded.
// Dynamic import used here to ensure this module is loaded after the browser has loaded
// https://nextjs.org/docs/advanced-features/dynamic-import
const TrackingLayer = dynamic(
  () => import('components/TrackingLayer/TrackingLayer.tsx'),
  { ssr: false }
);

const httpLink = createHttpLink({
  uri: graphqlApiPath,
});

const authorizationLink = onError(({ forward, networkError, operation }) => {
  const isClient = typeof document !== 'undefined';
  if (isClient && networkError) {
    handleAuthErrorClientSide(networkError.statusCode);
  }
  forward(operation);
});

// This is a customization of the original implementation of relayStylePagination in apollo:
//  - https://github.com/apollographql/apollo-client/blob/v3.2.7/src/utilities/policies/pagination.ts#L91
// It solves a particular bug related to pagination of comments/replies.
// If you you delete the topmost comment and then click "View previous comments", the now-topmost comment
// will be returned in the fetchMore call, which causes a comment to be duplicated in the list.
// This occurs because the "read" function in relayStylePagination() sets the startCursor to be the
// "cursor" attribute of the first comment we have in the cache. This happens on the following line:
//  - https://github.com/apollographql/apollo-client/blob/v3.2.7/src/utilities/policies/pagination.ts#L109
// When the first comment is deleted from the cache (and the backend), the startCursor is updated to be the
// cursor of the now-first comment in the cache. However, because there are less comments now in the backend,
// the comments returned before a particular cursor are all shifted by 1.
// This customization ensures that the startCursor is not updated and will always equal the value defined
// in the pageInfo.
const relayPagination = relayStylePagination(['orderBy']);
const customizedRelayPagination = {
  ...relayPagination,
  read(existing, options) {
    if (!existing) return undefined;

    const { startCursor } = existing.pageInfo;
    const originalRead = relayPagination.read(existing, options);

    return {
      ...originalRead,
      pageInfo: {
        ...originalRead.pageInfo,
        startCursor,
      },
    };
  },
};

const headersLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Client-ID': 'COMMUNITIES',
    },
  };
});

const apolloClient = new ApolloClient({
  link: authorizationLink.concat(headersLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Community: {
        fields: {
          posts: relayStylePagination(['first', 'filter', 'orderBy']),
        },
      },
      Post: {
        fields: {
          replies: customizedRelayPagination,
        },
      },
      Reply: {
        fields: {
          replies: customizedRelayPagination,
        },
      },
      User: {
        fields: {
          enrollments: relayStylePagination(),
        },
      },
      CommunitySection: {
        fields: {
          communitySpaces: {
            merge: true,
          },
        },
      },
    },
  }),
});

// This function is used to redirect the browser to sign-in when there is
// a graph authorization error client-side
const handleAuthErrorClientSide = (statusCode) => {
  i18n.loadNamespaces('common', (err) => {
    if (err) {
      toaster.alert('Something went wrong. Please try again.', {
        autoClose: false,
      });
    } else if (statusCode === 401) {
      // 401 Unauthorized means they are not logged-in
      Router.replace(
        `/users/sign_in?redirect_to=${
          document.location.pathname
        }&error=${i18n.t('Please sign in to continue')}`
      );
    } else if (statusCode === 403) {
      // 403 Forbidden means they are logged-in but not allowed to view the community
      Router.replace(
        `/enrollments?error=${i18n.t(
          'You are not authorized to see this page'
        )}`
      );
    } else if (statusCode === 404 || statusCode === 410) {
      // 410 response means the tenant has been marked as inactive
      Router.replace('/404.html');
    } else {
      genericToastAlert();
    }
  });
};

class MyApp extends App {
  constructor(props) {
    super(props);
    this.state = {
      postListState: {
        useCacheFirst: false, // This is a magical piece of global state that makes our PostList super fun to work on.
      },
    };
  }

  static async getInitialProps({ Component, ctx }) {
    const {
      query: { communityId },
    } = ctx;

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    pageProps = { communityId, ...pageProps };

    return { pageProps };
  }

  render() {
    const { Component, pageProps } = this.props;
    const { communityId } = pageProps;

    const passedPageProps = { ...this.state, ...pageProps };
    const getLayout =
      Component.getLayout || ((page) => <SiteLayout>{page}</SiteLayout>);
    return (
      <ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <CommunityProvider communityId={communityId}>
            <SiteFeaturesProvider>
              <RedirectUser>
                <TrackingLayer />
                {getLayout(<Component {...passedPageProps} />)}
                <ToastContainer />
              </RedirectUser>
            </SiteFeaturesProvider>
          </CommunityProvider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}

export default appWithTranslation(MyApp, nextI18NextConfig);
