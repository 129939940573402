import React, { MouseEvent, KeyboardEvent } from 'react';
import Icon from 'koba/components/Icon';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Draggable } from 'react-beautiful-dnd';

import { Space } from 'interfaces/space';
import { getIconNameByStoredValue } from 'helpers/spaceHelpers';

import { Tooltip } from '@thinkific/toga-react';
import { features } from 'constants/featureFlags';
import { useCommunityContext } from 'components/CommunityContext';
import styles from '../CommunityActionsList.module.scss';

const cx = classNames.bind(styles);

interface SpacePanelProps {
  handleClick(
    event: MouseEvent | KeyboardEvent,
    filter: string,
    name: string
  ): void;
  space: Space;
  index: number;
  isDragDisabled: boolean;
}

const SpacePanel: React.FC<SpacePanelProps> = ({
  handleClick,
  isDragDisabled,
  index,
  space,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { communityId, spaceId: activeRoute } = router.query;
  const { featureFlags } = useCommunityContext();
  const isExclusiveSpacesEnabled = featureFlags[features.EXCLUSIVE_SPACES];

  const ariaLabel = isDragDisabled
    ? t('Go to {{space}}', {
        space: space.name,
      })
    : t('Go to {{space}} or press space bar to reorder the Space', {
        space: space.name,
      });

  const handleSpacePanelClick = (event: MouseEvent | KeyboardEvent) =>
    handleClick(event, space.id, space.name);

  return (
    <Draggable
      draggableId={space.id}
      index={index}
      isDragDisabled={isDragDisabled}
      key={space.id}
    >
      {(provided, snapshot) => (
        // eslint-disable-next-line jsx-a11y/no-redundant-roles
        <a
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          aria-label={ariaLabel}
          className={cx('space__button', {
            'space__button--dragging': snapshot.isDragging,
            'is-active': activeRoute === space.id,
          })}
          data-qa="space-button"
          href={`/communities/${communityId}/spaces/${space.id}`}
          ref={provided.innerRef}
          role="link" // overwrite the role that comes from provided.dragHandleProps
          title={space.name}
          onClick={handleSpacePanelClick}
          onKeyPress={handleSpacePanelClick}
        >
          {!isDragDisabled && (
            <span
              className={cx('space__drag', {
                space__dragging: snapshot.isDragging,
              })}
            >
              <Icon name="handle-drag" />
            </span>
          )}
          <span className={cx('space__title')}>
            <Icon name={getIconNameByStoredValue(space?.iconName)} />
            <span>{space.name}</span>
          </span>
          {isExclusiveSpacesEnabled && space.isExclusive && (
            <Tooltip
              className={cx('space__tooltip')}
              title={t('common-Exclusive_Space', 'Exclusive Space')}
            >
              <Icon className={cx('space__access-indicator')} name="lock" />
            </Tooltip>
          )}
        </a>
      )}
    </Draggable>
  );
};

export default SpacePanel;
