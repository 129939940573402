import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './Image.module.scss';

const cx = classNames.bind(styles);

const placeholderSrc = (width, height) =>
  `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

interface ImgProps {
  src: string;
  alt?: string;
  width: number;
  height: number;
  className?: string;
}

/* This is image is responsive. The height and width are used to maintain the aspect ratio */
const ResponsiveImage: React.FC<ImgProps> = ({
  src: imgSrc,
  alt,
  width,
  height,
  className,
}) => {
  const placeholder = placeholderSrc(width, height);
  const [src, setSrc] = useState(placeholder);
  const [srcSet, setSrcSet] = useState('');

  const image = new Image();
  image.onload = () => {
    const source = imgSrc.includes('?') ? `${imgSrc}&` : `${imgSrc}?`;
    setSrcSet(
      `${source}width=${width} 1x, ${source}width=${width}&dpr=2 2x, ${source}width=${width}&dpr=3 3x`
    );
    setSrc(imgSrc);
  };
  image.src = imgSrc;

  return (
    <img alt={alt} className={cx('img', className)} src={src} srcSet={srcSet} />
  );
};

export default ResponsiveImage;
