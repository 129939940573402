export const hexToRGB = (h: string): number[] => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (h && h.length === 4) {
    r = Number(`0x${h[1]}${h[1]}`);
    g = Number(`0x${h[2]}${h[2]}`);
    b = Number(`0x${h[3]}${h[3]}`);
  } else if (h && h.length === 7) {
    r = Number(`0x${h[1]}${h[2]}`);
    g = Number(`0x${h[3]}${h[4]}`);
    b = Number(`0x${h[5]}${h[6]}`);
  }

  return [r, g, b];
};

export const hexToLightness = (hexColor: string): number => {
  const [r, g, b] = hexToRGB(hexColor);
  return (1 / 2) * (Math.max(r, g, b) + Math.min(r, g, b));
};

export const isTextDark = (hexColor: string): boolean =>
  Math.round((hexToLightness(hexColor) / 255) * 100) > 60;
