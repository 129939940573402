import PropTypes from 'prop-types';

export const DIMENSIONS_PROP_TYPE = PropTypes.shape({
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

export const DIMENSIONS_DEFAULT_PROP = {
  maxWidth: null,
  minWidth: null,
  width: null,
};

export const DROPDOWN_DIMENSIONS_PROP_TYPE = PropTypes.shape({
  menu: DIMENSIONS_PROP_TYPE,
  toggle: DIMENSIONS_PROP_TYPE,
});

export const DROPDOWN_DIMENSIONS_DEFAULT_PROP = {
  menu: DIMENSIONS_DEFAULT_PROP,
  toggle: DIMENSIONS_DEFAULT_PROP,
};
