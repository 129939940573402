import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Surface, Icon, Badge } from '@thinkific/toga-react';

import Image from 'components/Image';
import { SPACE_IMAGE_PLACEHOLDER } from 'utils/constants';
import { getIconNameByStoredValue } from 'helpers/spaceHelpers';

import { useTranslation } from 'react-i18next';
import styles from './SpaceDetailsCard.module.scss';

const cx = classNames.bind(styles);

interface SpaceDetailsCardProps {
  name?: string;
  description?: string;
  icon?: string;
  imageUrl?: string;
  imageAltText?: string;
  isExclusiveMember?: boolean;
  className?: string;
  footerAccessory?: ReactNode;
  dataQA?: string;
  showImage?: boolean;
  inheritPrimaryColor?: boolean;
}

const SpaceDetailsCard: React.FC<SpaceDetailsCardProps> = ({
  name,
  description,
  icon,
  imageUrl,
  imageAltText,
  isExclusiveMember,
  className,
  footerAccessory,
  dataQA,
  showImage = true,
  inheritPrimaryColor = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('space-details__container', className)} data-qa={dataQA}>
      <Surface className={cx('space-details__wrapper')}>
        {showImage && (
          <div className={cx('space-details__image')}>
            <Image
              alt={imageAltText || ''}
              height={294}
              src={imageUrl || SPACE_IMAGE_PLACEHOLDER}
              width={524}
            />
          </div>
        )}
        <div className={cx('space-details__body', 'p-2')}>
          <div className={cx('space-details__header')}>
            {icon && (
              <Icon
                className={cx({
                  'space-details__icon--inherit': inheritPrimaryColor,
                })}
                data-qa="space-card-icon__image"
                name={getIconNameByStoredValue(icon)}
              />
            )}
            <h2
              className={cx('space-details__title', 'h3')}
              data-qa="space-card-name__text"
              title={name}
            >
              {name}
            </h2>
          </div>
          {!isExclusiveMember && (
            <div
              className={cx('space-details__description', 'mt-2')}
              data-qa="space-card-description__text"
              title={description}
            >
              {description}
            </div>
          )}
          {isExclusiveMember && (
            <div>
              <Badge
                className={cx('space-details__badge')}
                icon="lock"
                type="secondary"
              >
                <span>{t('common-Exclusive', 'Exclusive')}</span>
              </Badge>
            </div>
          )}
        </div>
        {!isExclusiveMember && footerAccessory && (
          <div className={cx('space-details__footer')}>{footerAccessory}</div>
        )}
      </Surface>
    </div>
  );
};

export default SpaceDetailsCard;
