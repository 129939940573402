import { gql } from '@apollo/client';

export const SITE_FEATURES_QUERY = gql`
  query SiteFeatures($communityId: ID!) {
    site {
      id
      features {
        communities {
          maxCommunitySpaces
        }
        communityEvents {
          isEnabled
        }
        communityEventNotifications {
          isEnabled
        }
      }
    }
    community(id: $communityId) {
      id
      communitySections {
        edges {
          node {
            id
            communitySpaces {
              totalCount
            }
          }
        }
      }
    }
  }
`;
