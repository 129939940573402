import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../Dropdown.module.scss';

const cx = classNames.bind(styles) // eslint-disable-line

/**
 * React component which allows selection of a single item from a dropdown list
 * Substitute for the native select element.
 */
const ItemHeader = (props) => {
  const { children, ...attrs } = props;

  return (
    <li className={cx('dropdown__list-item-header')} {...attrs}>
      {children}
    </li>
  );
};

ItemHeader.propTypes = {
  /** HTML Node that will appear in the component */
  children: PropTypes.node,
};

ItemHeader.defaultProps = {
  children: null,
};

export default ItemHeader;
