import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Badge } from '@thinkific/toga-react';
import styles from './ModeratorBadge.module.scss';

const cx = classNames.bind(styles);

interface ModeratorBadgeProps {
  className?: string;
  children: ReactNode;
}

export const ModeratorBadge: React.FC<ModeratorBadgeProps> = ({
  className,
  children,
}) => <Badge className={cx('moderator-badge', className)}>{children}</Badge>;

export default ModeratorBadge;
