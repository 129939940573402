import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@thinkific/toga-react';
import { TIMEZONE_FORMAT } from 'helpers/dateHelpers/index';
import { H2, H3, P } from 'koba/components/Typography';
import MenuTab from 'koba/components/MobileMenu/components/MenuTab';
import UserAvatar from 'components/UserAvatar';
import { useCommunityContext } from 'components/CommunityContext';
import { COURSES_ON_PROFILE_VIEW, SCREEN_LG } from 'utils/constants';
import { isModerator } from 'utils/userHelpers';
import { useWindowSize } from 'hooks/useWindowSize';
import { USER_QUERY } from 'schema/User/queries';
import { genericToastAlert } from 'utils/toastHelpers';
import { UserQuery } from '__generated__/graphql/legacy/graphql';
import TrackingEvent from 'constants/trackingEvents';
import ModeratorBadge from 'components/ModeratorBadge';
import ProfilePanelSkeleton from './components/ProfilePanelSkeleton/ProfilePanelSkeleton';
import ProfilePastActivity from './components/ProfilePastActivity/ProfilePastActivity';

import styles from './ProfilePanel.module.scss';
import ProfileCompletedCourses from './components/ProfileCompletedCourses/ProfileCompletedCourses';

const cx = classNames.bind(styles);
interface ProfilePanelProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
}

const ProfilePanel: React.FC<ProfilePanelProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const { t } = useTranslation();
  const { trackEvent, currentUser, community } = useCommunityContext();
  const { width } = useWindowSize();
  const isMobile = width < SCREEN_LG;

  const {
    data,
    loading: isUserQueryLoading,
    fetchMore,
    previousData,
  } = useQuery<UserQuery>(USER_QUERY, {
    variables: {
      communityId: community.id,
      userId,
      numOfCourses: COURSES_ON_PROFILE_VIEW,
    },
    fetchPolicy: 'cache-and-network',
    skip: !userId,
    onError: () => {
      genericToastAlert();
    },
  });

  const user = data?.community?.communityUser?.user;

  const isAnAdminModerator = user?.roles?.length
    ? isModerator(user.roles)
    : false;
  const roles = isAnAdminModerator ? t('Moderator') : user?.roles?.join(', ');

  const renderModeratorBadge = () => {
    // The flag related condition needs to be deleted along with the flag removal.
    if (isAnAdminModerator) {
      return (
        <div className={cx('profile-panel__moderator-badge__container')}>
          <ModeratorBadge>{t('common-MODERATOR', 'MODERATOR')}</ModeratorBadge>
        </div>
      );
    }

    return (
      <span className={cx('profile-panel__roles')}>
        {t('common-student', 'Student')}
      </span>
    );
  };

  const isDifferentUser =
    user?.id !== previousData?.community?.communityUser?.user?.id;

  const handleEditProfile = (event) => {
    event.preventDefault();
    trackEvent(TrackingEvent.EDIT_PROFILE_FROM_PROFILE_PANEL_CLICKED, {});
    window.location.href = '/account';
  };

  const formatUserLocalTime = () => {
    const localTime = DateTime.now()
      .setZone(user?.profile?.timezone || '')
      .toFormat(TIMEZONE_FORMAT, {
        locale: currentUser.locale,
      });

    return localTime;
  };

  const onLoadMoreCourses = async () => {
    setIsLoadingMore(true);
    await fetchMore({
      variables: {
        endCursor: user?.enrollments?.pageInfo?.endCursor,
      },
    });
    setIsLoadingMore(false);
  };

  const renderSubtitle = () => {
    if (!user?.profile) return null;

    const { headline, company } = user?.profile;

    if (!headline && !company) return null;

    let subtitle = '';

    if (headline) {
      subtitle += headline;
    }

    if (company) {
      if (subtitle.trim()) {
        subtitle += `, ${company}`;
      } else {
        subtitle += company;
      }
    }

    return (
      <H3 className={cx('profile-panel__subtitle')} title={subtitle}>
        {subtitle}
      </H3>
    );
  };

  const closeButton = !isMobile && (
    <IconButton
      appearance="utility"
      className={cx('profile-panel__close')}
      data-qa="profile-panel__close"
      name="x-dismiss"
      title={t('Close profile panel')}
      onClick={onClose}
    />
  );

  const hasNoProfile =
    !isUserQueryLoading && userId && !data?.community?.communityUser;

  return (
    <MenuTab
      hasHeader={isMobile}
      hasOverlay={isMobile}
      isOpen={isOpen}
      menuTitle={t('Profile')}
      size={isMobile ? 'medium' : 'large'}
      slideTo="left"
      onClose={onClose}
    >
      <aside
        aria-label={`${user?.fullName} profile.`}
        className={cx('profile-panel')}
        data-qa="profile-panel__wrapper"
        role="dialog"
      >
        {isUserQueryLoading && isDifferentUser ? (
          <div className={cx('profile-panel__skeleton')}>
            <ProfilePanelSkeleton />
            {closeButton}
          </div>
        ) : (
          <div className={cx('profile-panel__content')}>
            <div className={cx('profile-panel__header')}>
              <UserAvatar
                avatarUrl={user?.profile?.avatarUrl || ''}
                className={cx('profile-panel__avatar')}
                size="lg"
                srcSet={`${user?.profile?.avatarUrl}?width=148 1x, ${user?.profile?.avatarUrl}?width=148&dpr=2 2x, ${user?.profile?.avatarUrl}?width=148&dpr=3 3x`}
                userId={user?.id || ''}
                username={user?.fullName || ''}
              />
              {closeButton}
            </div>
            <div className={cx('profile-panel__info')}>
              {user?.fullName && (
                <H2
                  className={cx('profile-panel__title')}
                  title={user?.fullName}
                >
                  {user?.fullName}
                </H2>
              )}
              {user?.profile && renderSubtitle()}
              {user?.profile?.timezone && (
                <P className={cx('profile-panel__label')}>
                  {t('Local time')} <span>{formatUserLocalTime()}</span>
                </P>
              )}
              <div className={cx('profile-panel__label')}>
                {t('Community Role')}

                {renderModeratorBadge()}
              </div>
              {currentUser.id === userId && (
                <a
                  className={cx('profile-panel__link')}
                  href="/account"
                  onClick={handleEditProfile}
                >
                  {t('Edit Profile')}
                </a>
              )}
            </div>

            {!roles?.length && (
              <ProfileCompletedCourses
                enrollments={user?.enrollments}
                isLoadingMore={isLoadingMore}
                onLoadMoreCourses={onLoadMoreCourses}
              />
            )}
          </div>
        )}

        {hasNoProfile ? (
          <section className={cx('profile-panel__no-info')}>
            <H2
              className={cx('profile-panel__no-info__title')}
              data-qa="profile-panel__no-info__title"
            >
              {t(
                'components-profile-no_profile_information',
                'No profile information.'
              )}
            </H2>
            <P className={cx('profile-panel__no-info__text')}>
              {t(
                'components-profile-no_info_text',
                'This person may no longer be part of the community.'
              )}
            </P>
          </section>
        ) : (
          <ProfilePastActivity user={user} userId={userId} onClose={onClose} />
        )}
      </aside>
    </MenuTab>
  );
};

export default ProfilePanel;
