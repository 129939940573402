import { gql } from '@apollo/client';

export const LIVE_EVENT_FRAGMENT = gql`
  fragment EventFields on LiveEvent {
    id
    title
    description
    imageUrl
    imageAltText
    startTime
    endTime
    timezone
    meetingUrl
    location
  }
`;

export const LIVE_EVENTS_EDGES_NODE_FRAGMENT = gql`
  fragment LiveEventsEdgesNodeFields on LiveEvent {
    interestedUsers {
      totalCount
    }
    isInterested
    ...EventFields
  }
  ${LIVE_EVENT_FRAGMENT}
`;

export const LIVE_EVENTS_EDGES_FRAGMENT = gql`
  fragment LiveEventsEdgesFields on LiveEventEdge {
    node {
      ...LiveEventsEdgesNodeFields
    }
  }
  ${LIVE_EVENTS_EDGES_NODE_FRAGMENT}
`;

export const LIVE_EVENTS_INTERESTED_USERS_EDGES_NODE_FRAGEMENT = gql`
  fragment InterestedUsersEdgesNodeFields on User {
    id
    fullName
    profile {
      id
      avatarUrl
    }
  }
`;

export const LIVE_EVENTS_INTERESTED_USERS_EDGES_FRAGEMENT = gql`
  fragment InterestedUsersEdgesFields on UserEdge {
    node {
      ...InterestedUsersEdgesNodeFields
    }
  }
  ${LIVE_EVENTS_INTERESTED_USERS_EDGES_NODE_FRAGEMENT}
`;
