export const eventNames = {
  SPACE_DESCRIPTION_CHANGE: 'SPACE_DESCRIPTION_CHANGE',
  SPACE_IMAGE_CHANGE: 'SPACE_IMAGE_CHANGE',
  OPEN_PROFILE_PANEL: 'OPEN_PROFILE_PANEL',
  SHOW_COURSE_CARD_PREVIEW: 'SHOW_COURSE_CARD_PREVIEW',
  HIDE_COURSE_CARD_PREVIEW: 'HIDE_COURSE_CARD_PREVIEW',
  OPEN_SELECT_COURSE_MODAL: 'OPEN_SELECT_COURSE_MODAL',

  // Live events
  OPEN_EVENT_PANEL: 'OPEN_EVENT_PANEL',

  // Posts
  OPEN_POST_MODAL: 'OPEN_POST_MODAL',

  // Moderator
  OPEN_MODERATOR_PANEL: 'OPEN_MODERATOR_PANEL',
};
